import { FormElement } from "UIKit";
import { useTranslation } from "react-i18next";
import { Control, useWatch } from "react-hook-form";
import useGetLocations from "Hooks/useGetLocations";
import { formatLocation } from "Utilities/helpers";
import { EolasSelect } from "UIKit/EolasSelect/EolasSelect";
import { LocationBasedFormInputs } from "./components/LocationBasedFormInputs";
import { Dispatch, SetStateAction } from "react";

interface CreateNewSpaceDetailsProps {
  control: Control<{
    spaceName: string;
    organisationName?: string;
    description: string;
    location: {
      label: string;
      value: string;
    };
    referralSource: {
      label: string;
      value: string;
    };
  }>;
  autoFocus?: boolean;
  isNameValid?: boolean;
  onSubmit: () => void;
  setSpaceLocation: Dispatch<SetStateAction<string>>;
}

const CreateNewSpaceDetails = ({
  control,
  onSubmit,
  isNameValid,
  setSpaceLocation,
}: CreateNewSpaceDetailsProps) => {
  const { t } = useTranslation();
  const { getLocations } = useGetLocations();

  // Use useWatch to always get the latest location value
  const selectedLocation = useWatch({ control, name: "location" });

  const handleFetchLocations = async (query: string) => {
    const locations = await getLocations(query);
    return locations.map((location) => formatLocation({ location }));
  };

  const referralOptions = [
    { value: "email", label: t("I received an email") },
    { value: "referral_from_colleague", label: t("From a colleague") },
    { value: "Within Eolas App", label: t("From within the Eolas app") },
    { value: "conference", label: t("Medical Conference") },
    { value: "Google search", label: t("Google search") },
    { value: "facebook", label: t("Facebook") },
    { value: "instagram", label: t("Instagram") },
    { value: "twitter", label: t("Twitter/X") },
    { value: "linkedin", label: t("Linkedin") },
    { value: "Reddit", label: t("Reddit") },
    { value: "scientific_publication", label: t("scientific_publication") },
    { value: "other", label: t("other") },
  ];

  return (
    <form onSubmit={onSubmit} className="min-h-50vh static">
      <FormElement
        required
        id="location"
        control={control}
        label={t("my-medical_personal_details_edit_profile_location")}
        labelClassName="text-base"
      >
        {(fieldProps) => (
          <EolasSelect
            {...fieldProps}
            mode="asynchronous"
            onChange={(selectedOption) => {
              fieldProps.onChange(selectedOption);
              setSpaceLocation(selectedOption.value);
            }}
            placeholder={t("my-medical_personal_details_edit_profile_location_placeholder")}
            isSearchable
            loadSuggestions={handleFetchLocations}
          />
        )}
      </FormElement>

      <LocationBasedFormInputs control={control} isNameValid={isNameValid} />

      {selectedLocation?.value ? (
        <FormElement
          required
          id="referralSource"
          control={control}
          label={t("complete_profile_new_institution_modal_referral_source")}
          labelClassName="text-base"
        >
          {(fieldProps) => (
            <EolasSelect
              {...fieldProps}
              mode="synchronous"
              options={referralOptions}
              placeholder={t("Please select an option")}
              isSearchable={false}
            />
          )}
        </FormElement>
      ) : null}
    </form>
  );
};

export default CreateNewSpaceDetails;
