import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import WorkDetailsForm from "./WorkDetailsForm";

interface UseWorkDetailsStepProps {
  onSubmit: (workDetails: { jobTitle: string; seniority: string; specialty: string[] }) => void;
  isLoading: boolean;
  defaultValues?: {
    jobTitle?: string;
    seniority?: string;
    specialty?: string[];
  };
}

export const useWorkDetailsStep = ({
  onSubmit,
  isLoading,
  defaultValues,
}: UseWorkDetailsStepProps): Step<"workDetails"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    jobTitle: yup.object().required(t("validation_job_title_required")),
    seniority: yup.object().required(t("validation_seniority_required")),
    specialty: yup.array().min(1, t("validation_specialty_required")).nullable(),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      jobTitle: defaultValues?.jobTitle
        ? { value: defaultValues.jobTitle, label: defaultValues.jobTitle }
        : undefined,
      seniority: defaultValues?.seniority
        ? { value: defaultValues.seniority, label: defaultValues.seniority }
        : undefined,
      specialty: defaultValues?.specialty
        ? defaultValues.specialty.map((s) => ({ value: s, label: s }))
        : [],
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleWorkDetailsSubmit = (data: {
    jobTitle: { value: string; label: string } | undefined;
    seniority: { value: string; label: string } | undefined;
    specialty: { value: string; label: string }[];
  }) => {
    if (!data.jobTitle || !data.seniority) return;

    onSubmit({
      jobTitle: data.jobTitle.value,
      seniority: data.seniority.value,
      specialty: data.specialty.map((s) => s.value),
    });
  };

  return {
    name: "workDetails",
    title: t("complete_profile_work_details_title"),
    subtitle: t("complete_profile_work_details_subtitle"),
    component: <WorkDetailsForm control={control} />,
    onSubmit: handleSubmit(handleWorkDetailsSubmit),
    canProceed: formState.isValid,
    isLoading: formState.isSubmitting || isLoading,
    submitError: !formState.isValid,
    backDisabled: true,
  };
};
