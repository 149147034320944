import React from "react";
import { useTranslation } from "react-i18next";
import { Button, Text } from "UIKit";

interface NewInstitutionConfirmationModalProps {
  institutionName: string;
  onConfirm: () => void;
  onCancel: () => void;
  isLoading: boolean;
  error: string | null;
}

const NewInstitutionConfirmationModal = ({
  institutionName,
  onConfirm,
  onCancel,
  isLoading,
  error,
}: NewInstitutionConfirmationModalProps) => {
  const { t } = useTranslation();

  return (
    <div
      className="fixed inset-0 z-[9999] flex items-center justify-center bg-black bg-opacity-50"
      style={{ backdropFilter: "blur(2px)" }}
    >
      <div className="rounded-lg shadow-xl p-8 max-w-md w-full m-4 animate-fadeIn bg-blue-50">
        <h2 className="text-2xl font-semibold mb-6 text-gray-800 text-center">
          {t("complete_profile_new_institution_modal_title")}
        </h2>

        <div className="space-y-4 mb-8 flex flex-col">
          <Text level={2} className="text-gray-700 leading-relaxed font-medium text-center">
            {t("complete_profile_new_institution_modal_message_part1")}
          </Text>

          <Text
            level={2}
            className="text-blue-500 leading-relaxed font-semibold text-center py-2 px-4 "
          >
            {institutionName}
          </Text>

          <Text level={2} className="text-gray-600 leading-relaxed mt-4">
            {t("complete_profile_new_institution_modal_suggestion")}
          </Text>
        </div>

        {error ? (
          <div className="bg-red-50 border border-red-200 rounded-md p-4 mb-6">
            <Text level={2} className="text-red-600">
              {error}
            </Text>
          </div>
        ) : null}

        <div className="flex justify-end gap-4 mt-8">
          <Button color="grey" variant="outline" onClick={onCancel} disabled={isLoading}>
            {t("complete_profile_new_institution_modal_back", "Go back")}
          </Button>
          <Button color="blue" variant="solid" onClick={onConfirm} isLoading={isLoading}>
            {t("complete_profile_new_institution_modal_add", "Add new")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NewInstitutionConfirmationModal;
