import React from "react";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormElement, Input } from "UIKit";

interface InstitutionDetailsFreeFormValues {
  institution: string;
}

interface InstitutionDetailsFreeFormProps {
  control: Control<InstitutionDetailsFreeFormValues>;
  backgroundColor?: string;
}

const InstitutionDetailsFreeForm = ({
  control,
  backgroundColor,
}: InstitutionDetailsFreeFormProps) => {
  const { t } = useTranslation();

  const { field } = useController({
    name: "institution",
    control,
  });

  return (
    <div className={`w-full${backgroundColor ? ` ${backgroundColor}` : ""}`}>
      <div className="flex flex-col justify-center">
        <div className="w-full mt-8">
          <FormElement
            labelClassName="text-base"
            required
            id="institution"
            control={control}
            label={t("complete_profile_healthcare_institution_label")}
          >
            {() => (
              <Input
                type="text"
                {...field}
                placeholder={t("complete_profile_healthcare_institution_placeholder")}
              />
            )}
          </FormElement>
        </div>
        <div className="h-40" />
      </div>
    </div>
  );
};

export default InstitutionDetailsFreeForm;
