import localforage from "localforage";
import envConfig from "env-config";
import Smartlook from "smartlook-client";

import {
  userStore,
  sectionStore,
  initialiseUserStore,
  initialiseCommunityClient,
  initialiseAccessClient,
  initialiseProfilesClient,
  initialiseSearchClient,
  initialiseApolloClient,
  initialiseInviteClient,
  initialiseSectionStore,
  initialiseLoginTokenClient,
  initHandleErrors,
  AxiosError,
  authenticationClient,
  initialiseAuthenticationClient,
  initialiseNationalResourcesClient,
  initialiseNotificationsClient,
  initialiseSpacesClient,
  initialiseMedusaClient,
  initialiseContentClient,
  initialiseCopilotClient,
  initialiseKnowledgeContentClient,
  initialiseBnfClient,
  initialiseNiceGuidelinesClient,
  initialiseMyFavouritesClient,
  initialiseMyFavouritesStore,
  initialiseSponsoredSlotClient,
  initialiseDocumentClient,
  initialiseClinicalQuestionsClient,
  initialiseClinicalQuestionStore,
  ApiClientParams,
  eolasLogger,
  initialiseInstitutionsClient,
} from "@eolas-medical/core";

import { Sentry } from "Contexts";
import { AppEnv } from "Utilities/types";
import { initMixpanel } from "API/Analytics";
import { errorStore } from "Stores/ErrorStore";

import { API_ENV_PATH_PREFIXS } from "./general";
import { EolasBabel } from "./babel";

import { onLogout, logout, onSuccessfulLogin } from "Pages/Authentication/auth.actions";
import { AuthClientParams } from "@eolas-medical/core/lib/src/clients/authentication/authentication.types";
import { initialiseLoginTokenService } from "API/LoginTokenService";
import { apiErrorsToIgnore, apolloClientDefaultOptions, apolloConfig, apolloLinks } from "config";
import { initialiseWebStore } from "Stores/WebStore";
import { initialisePortfolioService } from "modules/portfolio/client/PortfolioService";
import { initialiseMyFilesService } from "modules/myFiles/client/myFiles.client";
import { initialisePortfolioWizardService } from "modules/portfolio/client/PortfolioWizardService";
import { initialiseMedusaService } from "API/MedusaService";
import {
  contentDbStore,
  initialiseContentDbStore,
} from "Pages/Spaces/stores/contentDb/contentDb.store";
import { signoutSpaceCallBack } from "Hooks/useSignoutSpace";

const authServiceBaseUrl = `https://${envConfig.REACT_APP_API_BASE_URL}/auth/v1`;

const fatalSpaceGetErrors: { code: number[]; matchPattern: string }[] = [
  {
    code: [401, 403],
    matchPattern: "content-repository/v1/sections/*/content/?all=true&updatedAt=*",
  },
  {
    code: [404, 401, 403],
    matchPattern: "spaces/v1/space/*",
  },
];

export const startup = () => {
  initHandleErrors(apiErrorsToIgnore);
  const eolasBabel = new EolasBabel();

  const config = {
    Auth: {
      identityPoolId: envConfig.REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
      userPoolId: envConfig.REACT_APP_AWS_COGNITO_USER_POOL_ID,
      userPoolWebClientId: envConfig.REACT_APP_WEB_CLIENT_ID,
      endpoint: `${authServiceBaseUrl}/proxy`,
      region: envConfig.REACT_APP_AWS_REGION,
    },
    oauth: {
      domain: envConfig.REACT_APP_COGNITO_APP_DOMAIN,
      redirectSignIn: window.location.origin,
      redirectSignOut: window.location.origin,
      responseType: "code",
    },
    storage: eolasBabel,
  };

  const authConfig: AuthClientParams = {
    config,
    federatedDomains: envConfig.REACT_APP_SSO_DOMAINS.split(", "),
    baseUrl: authServiceBaseUrl,
    onSignIn: onSuccessfulLogin,
    onSignOut: () => {
      onLogout();
      eolasBabel.clear();
    },
    onRetrieveTokenError: (error) => {
      eolasLogger.error(error);
    },
  };

  const handleAxiosError = async (error: AxiosError) => {
    if (!userStore?.userSession.isLoggedIn) {
      return;
    }

    const isGet = ["get", "GET"].includes(error.config?.method ?? "");

    if (!isGet) {
      return;
    }

    const url = error.config?.url ?? "";

    const responseCode = error.response?.status ?? 0;

    const isProfileFetchError = url.match("profiles/v1/profiles/.*");

    if (isProfileFetchError && [401, 403].includes(responseCode)) {
      logout();
      return;
    }

    for (const { code, matchPattern } of fatalSpaceGetErrors) {
      if (code.includes(responseCode) && url.match(matchPattern) && sectionStore.appID) {
        signoutSpaceCallBack?.();
        return;
      }
    }
  };

  const initialiseClientsConfig: ApiClientParams = {
    environment: API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV],
    apiBaseUrl: `https://${envConfig.REACT_APP_API_BASE_URL}/`,
    handleAxiosError,
  };

  initialiseWebStore();

  initialiseSectionStore(
    {
      stringify: false,
      debugMode: false,
      name: "sectionStore",
      storage: localforage,
      expireIn: 24 * 60 * 60 * 1000,
    },
    false,
  );

  initialiseUserStore(
    {
      stringify: false,
      debugMode: false,
      name: "userStore",
      storage: localforage,
      expireIn: 24 * 60 * 60 * 1000,
    },
    false,
  );

  initialiseMyFavouritesStore({
    stringify: false,
    debugMode: false,
    name: "myFavouritesStore",
    storage: localforage,
    expireIn: 24 * 60 * 60 * 1000,
  });

  initialiseClinicalQuestionStore({
    stringify: false,
    debugMode: false,
    name: "clinicalQuestionStore",
    storage: localforage,
  });

  initialiseContentDbStore();

  initialiseAuthenticationClient(authConfig);

  initialiseApolloClient({
    config: apolloConfig,
    customClientLinks: apolloLinks,
    defaultOptions: apolloClientDefaultOptions,
  });

  initialiseInviteClient(initialiseClientsConfig);

  initialiseCommunityClient({ ...initialiseClientsConfig });

  initialiseProfilesClient(initialiseClientsConfig);

  initialiseAccessClient(initialiseClientsConfig);

  initialiseNationalResourcesClient(initialiseClientsConfig);

  initialiseLoginTokenService(initialiseClientsConfig);

  initialisePortfolioService(initialiseClientsConfig);

  initialiseMyFilesService(initialiseClientsConfig);

  initialiseNotificationsClient(initialiseClientsConfig);

  initialisePortfolioWizardService(initialiseClientsConfig);

  initialiseMedusaClient(initialiseClientsConfig);

  initialiseSpacesClient({
    ...initialiseClientsConfig,
    searchBaseUrl:
      envConfig.REACT_APP_APP_ENV === AppEnv.PRODUCTION
        ? "https://search.eolas.click"
        : `https://${API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV]}.search.eolas.click`,
  });

  const searchClientConfig = {
    ...initialiseClientsConfig,
    searchBaseUrl:
      envConfig.REACT_APP_APP_ENV === AppEnv.PRODUCTION
        ? "https://search.eolas.click"
        : `https://${API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV]}.search.eolas.click`,
  };

  initialiseInstitutionsClient(searchClientConfig);

  initialiseContentClient(initialiseClientsConfig);

  initialiseKnowledgeContentClient(searchClientConfig);

  initialiseCopilotClient(initialiseClientsConfig);

  initialiseSearchClient({
    environment: API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV],
    apiBaseUrl: searchClientConfig.searchBaseUrl,
  });

  initialiseLoginTokenClient({
    environment: API_ENV_PATH_PREFIXS[envConfig.REACT_APP_APP_ENV],
    apiBaseUrl: `https://${envConfig.REACT_APP_API_BASE_URL}/`,
  });

  initialiseBnfClient(initialiseClientsConfig);

  initialiseNiceGuidelinesClient(initialiseClientsConfig);

  initialiseNationalResourcesClient(initialiseClientsConfig);

  initialiseMyFavouritesClient(initialiseClientsConfig);

  initialiseSponsoredSlotClient(initialiseClientsConfig);

  initialiseClinicalQuestionsClient(initialiseClientsConfig);

  initialiseDocumentClient(initialiseClientsConfig);

  initialiseMedusaService(initialiseClientsConfig);

  initMixpanel();
};

const initiateSessionSentry = () => {
  Sentry.configureScope((scope) => {
    scope.addEventProcessor(async (event) => {
      event.contexts = {
        smartLook: {
          // eslint-disable-next-line camelcase
          session_id: Smartlook.sessionId,
          timestamp: new Date().getTime(),
        },
      };
      return event;
    });
  });
};

export const smartLookStartRecording = () => {
  initiateSessionSentry();
  Smartlook.init(envConfig.REACT_APP_SMARTLOOK_KEY);
};

export const globalWaitForHydration = async () => {
  const hydrationResults = await Promise.allSettled([
    sectionStore.isHydrated,
    userStore.isHydrated,
    authenticationClient.isHydrated,
    contentDbStore.isHydrated,
  ]);

  hydrationResults.forEach((outcome) => {
    if (outcome.status === "rejected") {
      errorStore.captureError(outcome.reason);
    }
  });
};
