import {
  ConfirmationModal,
  InnerModalWrapper,
  ModalBody,
  ModalHeader,
  Title,
  Button,
  Text,
} from "UIKit";
import { useTranslation } from "react-i18next";
import { ProfileVerification } from "@eolas-medical/core";
import { ProfileVerificationIcon } from "Assets/Icons";

export interface EditVerificationModalProps {
  onConfirm: () => void;
  onClose: () => void;
  profileVerification?: ProfileVerification;
}

const EditVerificationModal = ({
  onClose,
  onConfirm,
  profileVerification,
}: EditVerificationModalProps) => {
  const { t } = useTranslation();
  const isNotApplicable = profileVerification?.professionalBody === "NOT_APPLICABLE";

  if (isNotApplicable) {
    return (
      <InnerModalWrapper>
        <ModalHeader className="flex flex-col">
          <ProfileVerificationIcon className="h-12 w-12 mb-4" />
          <Title level={5}>{t("profile_verification_title")}</Title>
        </ModalHeader>
        <ModalBody className="space-y-4 sm:space-y-8">
          <Text level={1} className="text-center">
            {t("profile_verification_intro_text")}
          </Text>
          <div className="grid grid-cols-1 gap-2">
            <Button size="lg" color="grey" variant="outline" onClick={onClose} className="order-0">
              {t("profile_verification_change_professional_body")}
            </Button>
          </div>
        </ModalBody>
      </InnerModalWrapper>
    );
  }

  return (
    <ConfirmationModal
      onDecline={onClose}
      onAccept={onConfirm}
      iconType="ProfileVerificationIcon"
      title={t("profile_verification_title")}
      description={t("profile_verification_intro_text")}
      acceptLabel={t("profile_verification_edit_code")}
      declineLabel={t("profile_verification_change_professional_body")}
    />
  );
};

export default EditVerificationModal;
