export const FORM_FIELDS = {
  // Institution Details
  INSTITUTION: "institution",
  INSTITUTIONS: "institutions",

  // Professional Verification
  PROFESSIONAL_BODY: "professionalBody",
  PROFESSIONAL_VERIFICATION: "professionalVerification",

  // Work Details
  WORK_DETAILS: "workDetails",

  // Location
  LOCATION: "location",

  // Suggested Spaces
  SUGGESTED_SPACES: "suggestedSpaces",

  // Step Names
  INSTITUTION_DETAILS: "institutionDetails",
  FINAL: "final",
  SUCCESS: "success",
};
