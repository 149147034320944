import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import CreateNewSpaceDetails from "./CreateNewSpaceDetails";
import { trackEvent } from "API/Analytics";
import { AnalyticsEvents, UserLocation } from "@eolas-medical/core";
import useValidateSpaceName from "modules/spaces/data/useValidateSpace";
import { Dispatch, SetStateAction } from "react";

interface useCreateNewSpaceDetailsStepProps {
  onSubmit: ({
    spaceName,
    organisationName,
    description,
    location,
    referralSource,
  }: {
    spaceName: string;
    organisationName?: string;
    description: string;
    location?: UserLocation;
    referralSource: {
      label: string;
      value: string;
    };
  }) => void;
  onEnter: () => void;
  setSpaceLocation: Dispatch<SetStateAction<string>>;
  isLoading: boolean;
}

export const useCreateNewSpaceDetailsStep = ({
  onSubmit,
  onEnter,
  setSpaceLocation,
  isLoading,
}: useCreateNewSpaceDetailsStepProps): Step<"details"> => {
  const { t } = useTranslation();

  const { validateSpace } = useValidateSpaceName();

  const schema = yup.object().shape({
    spaceName: yup.string().required(t("validation_name_required")),
    location: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .test(
        "check-object-properties",
        t("validation_location_required"),
        (value: { label: string | undefined; value: string | undefined } | undefined) => {
          return value?.label?.trim() !== "" || value?.value?.trim() !== "";
        },
      ),
    referralSource: yup
      .object()
      .shape({
        value: yup.string(),
        label: yup.string(),
      })
      .test(
        "check-referral-source",
        t("validation_referral_source_required"),
        function (value: { label: string | undefined; value: string | undefined } | undefined) {
          // Get the location value from the parent object
          const location = this.parent.location;

          // If location has a value, then referralSource is required
          if (location && location.value && location.value.trim() !== "") {
            return value?.value !== undefined && value.value.trim() !== "";
          }

          // If no location is selected, referralSource is not required
          return true;
        },
      ),
  });

  const { control, handleSubmit, formState, getValues, setError } = useForm({
    defaultValues: {
      spaceName: "",
      description: "",
      location: { label: "", value: "" },
      referralSource: { label: "", value: "" },
    },
    resolver: yupResolver(schema),
    mode: "onChange",
    shouldUnregister: false,
  });

  const handleSubmitSpaceDetails = async () => {
    const { spaceName, description, location, referralSource } = getValues();
    const city = location?.value?.split(",")[0];
    const country = location?.value?.split(",")[1];
    const locationDetails = { city, country };

    const { name: spaceNameIsValid } = await validateSpace({
      spaceName: spaceName,
    });
    if (!spaceNameIsValid) {
      setError("spaceName", { message: t("space_name_exists_error") });
      trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_ALREADY_EXISTS);
    } else {
      trackEvent(AnalyticsEvents.SPACE_CREATION_FLOW_SPACE_NAME_COMPLETED);
      onSubmit({ spaceName, description, location: locationDetails, referralSource });
    }
  };

  const title = t("newSpaceModal_add_details_title");
  return {
    name: "details",
    title,
    component: (
      <CreateNewSpaceDetails
        control={control}
        onSubmit={onEnter}
        setSpaceLocation={setSpaceLocation}
        isNameValid={!formState.errors.spaceName ? formState.dirtyFields.spaceName : undefined}
      />
    ),
    onSubmit: handleSubmit(handleSubmitSpaceDetails),
    canProceed: formState.isValid,
    isLoading: formState.isSubmitting || isLoading,
    submitError: !formState.isValid,
  };
};
