import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Button, DatePicker, Text, Title } from "UIKit";

interface DateRangePickerModalProps {
  closeModal: () => void;
  onApply: (startDate: Date, endDate: Date) => void;
}

export const DateRangePickerModal = ({ closeModal, onApply }: DateRangePickerModalProps) => {
  const { t } = useTranslation();

  const [startDate, setStartDate] = useState<Date>(
    new Date(new Date().setMonth(new Date().getMonth() - 1)), // Default to 1 month ago
  );
  const [endDate, setEndDate] = useState<Date>(new Date());

  const handleStartDateChange = (date: Date | null) => {
    if (!date) return;
    setStartDate(date);

    if (endDate && date > endDate) {
      setEndDate(date);
    }
  };

  const handleEndDateChange = (date: Date | null) => {
    if (!date) return;
    setEndDate(date);
  };

  const onSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onApply(startDate, endDate);
    closeModal();
  };

  return (
    <div className="bg-white p-6 rounded-lg w-full max-w-lg">
      <Title level={5} className="mb-4">
        {t("download_snapshot_select_date_range")}
      </Title>

      <Text level={2} className="mb-6">
        {t("download_snapshot_select_date_range_description")}
      </Text>

      <form onSubmit={onSubmit} className="space-y-6">
        <div className="flex flex-col md:flex-row gap-4 h-80 min-w-96">
          <div className="flex-1">
            <Text level={2} className="mb-2 font-semibold">
              {t("from_date")}
            </Text>
            <DatePicker
              selected={startDate}
              onChange={handleStartDateChange}
              maxDate={new Date()}
              className="w-full"
            />
          </div>

          <div className="flex-1">
            <Text level={2} className="mb-2 font-semibold">
              {t("to_date")}
            </Text>
            <DatePicker
              selected={endDate}
              onChange={handleEndDateChange}
              minDate={startDate}
              maxDate={new Date()}
              className="w-full"
            />
          </div>
        </div>

        <div className="flex justify-end gap-3 pt-4">
          <Button variant="outline" color="grey" onClick={closeModal}>
            {t("cancel")}
          </Button>
          <Button type="submit" color="blue">
            {t("apply")}
          </Button>
        </div>
      </form>
    </div>
  );
};
