import React, { useState, useCallback, useRef, useEffect } from "react";
import AsyncCreatableSelect from "react-select/async-creatable";
import { GroupBase, OnChangeValue, ActionMeta, FormatOptionLabelMeta } from "react-select";
import { useTranslation } from "react-i18next";
import debounce from "lodash/debounce";
import { DebouncedFunc } from "lodash";
import { createSelectStyles } from "../../shared/selectStyles";
import { eolasLogger } from "@eolas-medical/core";

interface AsyncCreatableSelectProps<Option = unknown, IsMulti extends boolean = false> {
  loadOptions: (inputValue: string) => Promise<Option[]>;
  onChange?: (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => void;
  placeholder?: string;
  isMulti?: IsMulti;
  value?: OnChangeValue<Option, IsMulti>;
  name?: string;
  debounceMs?: number;
  onCreateOption?: (inputValue: string) => void;
  isSearchable?: boolean;
  isClearable?: boolean;
  className?: string;
  classNamePrefix?: string;
  formatOptionLabel?: (option: Option, labelMeta: FormatOptionLabelMeta<Option>) => React.ReactNode;
  menuPlacement?: "auto" | "top" | "bottom";
}

export const EolasAsyncCreatableSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  loadOptions,
  onChange,
  placeholder,
  isMulti,
  value,
  name,
  onCreateOption,
  isSearchable = true,
  isClearable = false,
  className,
  classNamePrefix,
  formatOptionLabel,
  menuPlacement = "auto",
  debounceMs = 300,
}: AsyncCreatableSelectProps<Option, IsMulti>) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);

  type LoadOptionsCallback = (options: Option[]) => void;
  type DebouncedLoadOptions = DebouncedFunc<(input: string, cb: LoadOptionsCallback) => void>;

  const debouncedFnRef = useRef<DebouncedLoadOptions | null>(null);

  const handleLoadOptions = useCallback(
    (inputValue: string, callback: LoadOptionsCallback) => {
      if (!inputValue || inputValue.length < 2) {
        callback([]);
        return;
      }

      loadOptions(inputValue)
        .then((options) => callback(options || []))
        .catch((error) => {
          eolasLogger.error(new Error("Error loading select options"), { error });
          callback([]);
        });
    },
    [loadOptions],
  );

  useEffect(() => {
    debouncedFnRef.current = debounce(handleLoadOptions, debounceMs);

    return () => {
      if (debouncedFnRef.current) {
        debouncedFnRef.current.cancel();
      }
    };
  }, [handleLoadOptions, debounceMs]);

  const wrappedLoadOptions = useCallback(
    (inputValue: string, callback: LoadOptionsCallback) => {
      if (debouncedFnRef.current) {
        debouncedFnRef.current(inputValue, callback);
      } else {
        handleLoadOptions(inputValue, callback);
      }
    },
    [handleLoadOptions],
  );

  return (
    <AsyncCreatableSelect
      cacheOptions
      loadOptions={wrappedLoadOptions}
      onChange={onChange}
      placeholder={placeholder || t("component_eolasSelect_placeholder_search")}
      isMulti={isMulti}
      value={value}
      name={name}
      styles={createSelectStyles<Option, IsMulti, Group>(!!isMulti)}
      onMenuClose={() => setIsOpen(false)}
      onMenuOpen={() => setIsOpen(true)}
      menuIsOpen={isOpen}
      isSearchable={isSearchable}
      isClearable={isClearable}
      closeMenuOnSelect={!isMulti}
      onCreateOption={onCreateOption}
      menuPlacement={menuPlacement}
      menuPosition="fixed"
      className={className}
      classNamePrefix={classNamePrefix}
      defaultOptions={[]}
      formatCreateLabel={(inputValue) => `Add "${inputValue}"`}
      formatOptionLabel={formatOptionLabel}
    />
  );
};
