import { modalStore } from "Stores/ModalStore";
import { TnCModal } from "../components";
import { useGetDisplayTsAndCsStatus } from "./useGetDisplayTsAndCsStatus";

export const useDisplayTsAndCs = () => {
  useGetDisplayTsAndCsStatus({
    onSuccess: ({ shouldDisplay, ...rest }) => {
      if (!shouldDisplay) {
        return;
      }
      modalStore.openModal({
        variant: "component",
        Component: <TnCModal {...rest} />,
        name: "TnCModal",
        shouldRenderCloseButton: false,
        isDismissible: false,
      });
    },
  });
};
