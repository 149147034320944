import { useTranslation } from "react-i18next";
import { useNotifications } from "Components/Notifications";
import { ChecklistItem, EolasFile, eolasLogger } from "@eolas-medical/core";
import { parseAwsJson } from "Utilities";
import { formatDate } from "Utilities/general";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { downloadCSV, escapeCsvValue } from "Utilities/csv";

interface ChecklistSection {
  checklistSectionName: string;
  skipped: boolean;
  sectionItems: ChecklistItem[];
}

interface CompletedChecklist {
  checklistName?: string;
  checklistIssues?: boolean;
  additionalInfo?: string;
  secondTeamMember?: string;
  checklistSections?: ChecklistSection[];
}

const generateChecklistSnapshotCSVContent = (files: EolasFile[]): string => {
  if (files.length === 0) {
    eolasLogger.error(new Error("No data available in files"));
    return "";
  }

  const parsedChecklists = files
    .map((file) => {
      try {
        const parsed = parseAwsJson(file.metadata ?? "{}") || {};
        const completedChecklist = (parsed.completedChecklist || {}) as CompletedChecklist;

        return {
          file,
          checklist: completedChecklist,
        };
      } catch (error) {
        eolasLogger.error(new Error(`Error parsing checklist: ${error}`));
        return null;
      }
    })
    .filter((data): data is { file: EolasFile; checklist: CompletedChecklist } => data !== null);

  if (parsedChecklists.length === 0) {
    return "No valid checklists found";
  }

  const rows: string[][] = [];

  const headerRow = ["Date"];
  parsedChecklists.forEach((data) => {
    headerRow.push(formatDate(new Date(data.file.createdAt || "")));
  });
  rows.push(headerRow);

  const completedByRow = ["Completed By"];
  parsedChecklists.forEach((data) => {
    completedByRow.push(data.file.info || "N/A");
  });
  rows.push(completedByRow);

  if (parsedChecklists.some((data) => Boolean(data.checklist.secondTeamMember))) {
    const secondTeamMemberRow = ["Second Team Member"];
    parsedChecklists.forEach((data) => {
      secondTeamMemberRow.push(data.checklist.secondTeamMember || "");
    });
    rows.push(secondTeamMemberRow);
  }

  if (parsedChecklists.some((data) => Boolean(data.checklist.checklistIssues))) {
    const issuesRow = ["Issues Flagged"];
    parsedChecklists.forEach((data) => {
      issuesRow.push(data.checklist.checklistIssues ? "Yes" : "No");
    });
    rows.push(issuesRow);
  }

  if (parsedChecklists.some((data) => Boolean(data.checklist.additionalInfo))) {
    const additionalInfoRow = ["Additional Info"];
    parsedChecklists.forEach((data) => {
      additionalInfoRow.push(data.checklist.additionalInfo || "");
    });
    rows.push(additionalInfoRow);
  }

  rows.push([""]);

  const templateChecklist = parsedChecklists[0].checklist;

  if (templateChecklist.checklistSections?.length) {
    templateChecklist.checklistSections.forEach((templateSection, sectionIndex) => {
      if (!templateSection) return;

      const sectionHeaderRow = [
        `SECTION ${sectionIndex + 1}: ${templateSection.checklistSectionName}`,
      ];

      parsedChecklists.forEach((data) => {
        const section = data.checklist.checklistSections?.[sectionIndex];
        if (!section) {
          sectionHeaderRow.push("N/A");
          return;
        }

        const isSkipped = section.skipped;
        const isComplete =
          !isSkipped &&
          section.sectionItems.every((item) =>
            item.itemType === "checkbox" ? item.checked : !!item.inputInfo,
          );

        sectionHeaderRow.push(isSkipped ? "SKIPPED" : isComplete ? "COMPLETE" : "INCOMPLETE");
      });

      rows.push(sectionHeaderRow);

      if (templateSection.sectionItems?.length) {
        templateSection.sectionItems.forEach((templateItem, itemIndex) => {
          const itemRow = [
            `${itemIndex + 1}. ${templateItem.itemTitle} - ${
              templateItem.itemType === "checkbox" ? "✓" : "Text"
            }`,
          ];

          parsedChecklists.forEach((data) => {
            const section = data.checklist.checklistSections?.[sectionIndex];
            if (!section || section.skipped) {
              itemRow.push("N/A");
              return;
            }

            const item = section.sectionItems[itemIndex];
            if (!item) {
              itemRow.push("N/A");
              return;
            }

            if (item.itemType === "checkbox") {
              itemRow.push(item.checked ? "tick" : "");
            } else {
              itemRow.push(item.inputInfo || "");
            }
          });

          rows.push(itemRow);
        });
      }

      rows.push([""]);
    });
  }

  return rows.map((row) => row.map((cell) => escapeCsvValue(cell)).join(",")).join("\n");
};

export const useDownloadChecklistSnapshot = () => {
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const { flags } = useLaunchDarkly();

  const handleDownloadSnapshot = (
    files: EolasFile[],
    checklistName: string,
    startDate?: Date,
    endDate?: Date,
  ) => {
    let filteredFiles = files;

    if (startDate && endDate) {
      filteredFiles = files.filter((file) => {
        const fileDate = new Date(file.createdAt || "");
        const startOfDay = new Date(startDate);
        startOfDay.setHours(0, 0, 0, 0);

        const endOfDay = new Date(endDate);
        endOfDay.setHours(23, 59, 59, 999);

        return fileDate >= startOfDay && fileDate <= endOfDay;
      });
    }

    const csvContent = generateChecklistSnapshotCSVContent(filteredFiles);
    downloadCSV(
      csvContent,
      `${checklistName} Snapshot.csv`,
      (outcome) => {
        showNotification({
          type: outcome === "success" ? "success" : "error",
          autoHideTimeout: 5000,
          description:
            outcome === "success" ? t("export_snapshot_success") : t("export_snapshot_failure"),
        });
      },
      "Failed to export checklist snapshot CSV",
    );
  };

  const showDownloadSnapshot = flags[LDFlagNames.ENABLE_CHECKLIST_SNAPSHOT_DOWNLOAD] || false;

  return { handleDownloadSnapshot, showDownloadSnapshot };
};
