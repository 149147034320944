import React from "react";
import { OnChangeValue, ActionMeta } from "react-select";
import { EnhancedSelect } from "./EnhancedSelect";

export interface EnhancedSyncSelectProps<Option = unknown, IsMulti extends boolean = false> {
  onChange?: (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => void;
  placeholder?: string;
  isMulti?: IsMulti;
  value?: OnChangeValue<Option, IsMulti>;
  name?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  className?: string;
  classNamePrefix?: string;
  options: Option[];
  menuPlacement?: "auto" | "bottom" | "top";
}

export const EnhancedSyncSelect = <Option = unknown, IsMulti extends boolean = false>({
  onChange,
  placeholder,
  isMulti,
  value,
  name,
  isSearchable = true,
  isClearable = false,
  className,
  classNamePrefix,
  options,
  menuPlacement = "auto",
}: EnhancedSyncSelectProps<Option, IsMulti>) => {
  return (
    <EnhancedSelect
      options={options}
      onChange={onChange}
      placeholder={placeholder}
      isMulti={isMulti}
      value={value}
      name={name}
      isSearchable={isSearchable}
      isClearable={isClearable}
      className={className}
      classNamePrefix={classNamePrefix}
      menuPlacement={menuPlacement}
    />
  );
};
