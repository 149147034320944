import { accessClient, eolasLogger } from "@eolas-medical/core";
import { ShouldAllowUserAccessResponse } from "@eolas-medical/core/lib/src/clients/access/access.types";
import { useQuery } from "@tanstack/react-query";

type Props = {
  spaceId: string;
  isEnabled?: boolean;
  onSuccess?: (data: ShouldAllowUserAccessResponse) => void;
};

export const useCheckSpaceAccess = ({ spaceId, isEnabled = true, onSuccess }: Props) => {
  const { data, isFetching, isError, error, refetch } = useQuery({
    queryKey: ["checkSpaceAccess", spaceId],
    queryFn: async () => {
      try {
        return await accessClient.shouldAllowUserAccess(spaceId);
      } catch (err) {
        eolasLogger.error(new Error(`Error checking space access for space ${spaceId}`), {
          error: err,
        });
        throw err;
      }
    },
    enabled: isEnabled && Boolean(spaceId),
    onSuccess,
    staleTime: 60000, // 1 minute
  });

  return {
    isFetching,
    data: data || null,
    isError,
    error,
    refetch,
  };
};
