import { useCallback, useState } from "react";
import { institutionsClient } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import { useDebounce } from "use-debounce";

const DEFAULT_INSTITUTION_TYPE = "HEALTHCARE";

interface Institution {
  id: string;
  name: string;
  nameVariations?: string[];
}

interface InstitutionOption {
  label: string;
  value: string;
  nameVariations?: string[];
  __isNew__?: boolean;
}

/**
 * React Query hook for searching institutions with built-in debouncing
 */
export const useInstitutionsSearch = (
  inputValue: string,
  debounceMs: number = 300,
  enabled: boolean = true,
) => {
  const [debouncedInputValue] = useDebounce(inputValue, debounceMs);

  const shouldSearch = Boolean(debouncedInputValue && debouncedInputValue.length >= 2 && enabled);

  const { data, isLoading, error } = useQuery<InstitutionOption[], Error>({
    queryKey: ["institutions", debouncedInputValue],
    queryFn: async () => {
      const response = await institutionsClient.searchInstitutions(
        debouncedInputValue,
        DEFAULT_INSTITUTION_TYPE,
      );

      if (!response?.items || !Array.isArray(response.items) || response.items.length === 0) {
        return [];
      }

      return response.items
        .filter((item: Institution) => item && item.id && item.name)
        .map((item: Institution) => ({
          label: item.name,
          value: item.id,
          nameVariations:
            item.nameVariations && item.nameVariations.length > 0 ? item.nameVariations : undefined,
        }));
    },
    enabled: shouldSearch,
    staleTime: 60000,
    keepPreviousData: true,
  });

  return {
    options: data || [],
    isLoading,
    error,
    noResultsFound: shouldSearch && data && data.length === 0,
  };
};

// Keep the original hook for backward compatibility
export const useInstitutionsLoadOptions = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [noResultsFound, setNoResultsFound] = useState(false);

  const loadInstitutionOptions = useCallback(
    async (inputValue: string): Promise<InstitutionOption[]> => {
      if (!inputValue || inputValue.length < 2) {
        setNoResultsFound(false);
        return [];
      }

      setIsLoading(true);
      setError(null);

      try {
        const response = await institutionsClient.searchInstitutions(
          inputValue,
          DEFAULT_INSTITUTION_TYPE,
        );

        if (!response?.items || !Array.isArray(response.items) || response.items.length === 0) {
          setNoResultsFound(true);
          return [];
        }

        setNoResultsFound(false);
        const options = response.items
          .filter((item: Institution) => item && item.id && item.name)
          .map((item: Institution) => ({
            label: item.name,
            value: item.id,
            nameVariations:
              item.nameVariations && item.nameVariations.length > 0
                ? item.nameVariations
                : undefined,
          }));

        return options;
      } catch (error) {
        console.error("Error fetching institutions:", error);
        setError(error instanceof Error ? error : new Error("Failed to fetch institutions"));
        setNoResultsFound(true);
        return [];
      } finally {
        setIsLoading(false);
      }
    },
    [],
  );

  const handleCreateOption = useCallback((inputValue: string): InstitutionOption => {
    return {
      label: inputValue,
      value: `new:${inputValue}`,
      __isNew__: true,
    };
  }, []);

  return {
    loadInstitutionOptions,
    handleCreateOption,
    isLoading,
    error,
    noResultsFound,
  };
};
