import React, { useState, useCallback, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Space, eolasLogger } from "@eolas-medical/core";
import CompactSpaceTile from "./CompactSpaceTile";
import { Modal, Pill } from "UIKit";
import { PreviewSpaceModal } from "Pages/Spaces/pages/SelectSpace/components/DiscoverSpaces/components/PreviewSpaceModal/PreviewSpaceModal";
import { useCheckSpaceAccess } from "Hooks/useCheckSpaceAccess";
import { Sizes } from "UIKit/types";
import { AccessRequestStatus } from "Pages/Spaces/pages/SelectSpace/types";
import { queryClient } from "config/reactQuery";

interface SuggestedSpacesFormProps {
  suggestedSpaces: Space[];
  isLoading: boolean;
  backgroundColor?: string;
  onNext: () => void;
  /** Callback to notify parent when any space access status changes.
   * This is used to update UI elements like button labels.
   * The callback is properly memoized in the parent component to prevent infinite loops. */
  onAccessRequestedChange?: (hasRequestedAccess: boolean) => void;
}

const SuggestedSpacesForm = ({
  suggestedSpaces,
  backgroundColor,
  onAccessRequestedChange,
}: SuggestedSpacesFormProps) => {
  const [selectedSpace, setSelectedSpace] = useState<Space | null>(null);
  const [spacesWithRequestedAccess, setSpacesWithRequestedAccess] = useState<
    Record<string, boolean>
  >({});

  const handleSpaceClick = useCallback((space: Space) => {
    setSelectedSpace(space);
  }, []);

  const handleCloseModal = useCallback(() => {
    setSelectedSpace(null);
  }, []);

  const updateSpaceAccessStatus = useCallback(
    (spaceId: string, hasRequestedAccess: boolean) => {
      setSpacesWithRequestedAccess((prev) => {
        if (prev[spaceId] === hasRequestedAccess) {
          return prev;
        }

        const newState = {
          ...prev,
          [spaceId]: hasRequestedAccess,
        };

        const hasAnyRequested = Object.values(newState).some(Boolean);

        if (onAccessRequestedChange) {
          onAccessRequestedChange(hasAnyRequested);
        }

        return newState;
      });
    },
    [onAccessRequestedChange],
  );

  const refetchAllSpacesAccess = useCallback(() => {
    try {
      suggestedSpaces.forEach((space) => {
        queryClient.invalidateQueries(["checkSpaceAccess", space.id]);
      });
    } catch (error) {
      eolasLogger.error(new Error("Error invalidating space access queries"), { error });
    }
  }, [suggestedSpaces]);

  const handleModalClose = useCallback(() => {
    handleCloseModal();
    refetchAllSpacesAccess();
  }, [handleCloseModal, refetchAllSpacesAccess]);

  return (
    <div className={`w-full ${backgroundColor || ""}`}>
      <div className="flex flex-col justify-center">
        <div className="w-full mt-8 flex flex-col gap-4 overflow-y-auto max-h-[60vh] min-h-40vh">
          {suggestedSpaces.map((space) => (
            <SpaceAccessWrapper
              key={space.id}
              space={space}
              onSpaceClick={handleSpaceClick}
              onAccessStatusUpdate={updateSpaceAccessStatus}
              hasRequestedAccess={spacesWithRequestedAccess[space.id] || false}
            />
          ))}
        </div>
      </div>
      <Modal open={!!selectedSpace} onClose={handleCloseModal}>
        {selectedSpace ? (
          <PreviewSpaceModal space={selectedSpace} onClose={handleModalClose} />
        ) : null}
      </Modal>
    </div>
  );
};

interface SpaceAccessWrapperProps {
  space: Space;
  onSpaceClick: (space: Space) => void;
  onAccessStatusUpdate: (spaceId: string, hasRequestedAccess: boolean) => void;
  hasRequestedAccess: boolean;
}

const SpaceAccessWrapper: React.FC<SpaceAccessWrapperProps> = ({
  space,
  onSpaceClick,
  onAccessStatusUpdate,
  hasRequestedAccess,
}) => {
  const { t } = useTranslation();

  const { refetch } = useCheckSpaceAccess({
    spaceId: space.id,
    onSuccess: (data) => {
      if (data) {
        const hasRequested = data.status === AccessRequestStatus.ACCESS_REQUESTED;
        onAccessStatusUpdate(space.id, hasRequested);
      }
    },
  });

  useEffect(() => {
    refetch().catch((error) => {
      eolasLogger.error(new Error(`Error fetching access status for space ${space.id}`), { error });
    });
  }, [refetch, hasRequestedAccess, space.id]);

  return (
    <div className="relative">
      {hasRequestedAccess ? (
        <div className="absolute top-2 right-2 z-10">
          <Pill
            value={t("complete_profile_access_requested")}
            className="bg-blue-100 text-blue-700"
            size={Sizes.EXTRA_SMALL}
          />
        </div>
      ) : null}
      <CompactSpaceTile
        id={space.id}
        name={space.name}
        description={space.description || ""}
        membersCount={space.userCount || 0}
        isPrivate={space.accessType !== "public"}
        imageUrl={space.image?.url}
        onClick={() => onSpaceClick(space)}
      />
    </div>
  );
};

export default SuggestedSpacesForm;
