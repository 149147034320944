import React from "react";
import { useTranslation } from "react-i18next";

import { LoginTokenIcon } from "Assets";
import { Text, Title, Button, ModalBody, SuccessModal, InnerModalWrapper } from "UIKit";

import { useCreateLoginToken } from "./useCreateLoginToken";

export interface CreateLoginTokenModalProps {
  onCloseModal(): void;
}

export const CreateLoginTokenModal: React.FC<CreateLoginTokenModalProps> = ({
  onCloseModal,
}: CreateLoginTokenModalProps) => {
  const { t } = useTranslation();
  const { createToken, isSuccessful, isLoading, error } = useCreateLoginToken();

  if (isSuccessful) {
    return <SuccessModal onComplete={onCloseModal} text="manageUsers_login_tokens_modal_success" />;
  }

  return (
    <InnerModalWrapper>
      <ModalBody>
        <LoginTokenIcon className="w-20 h-20 self-center" />

        <Title className="text-center mt-6 mb-3" level={5}>
          {t("manageUsers_login_tokens_title")}
        </Title>

        <Text level={2}>{t("manageUsers_login_tokens_modal_desc")}</Text>

        {error ? (
          <Text level={2} className="text-red-600 mt-4">
            {error}
          </Text>
        ) : null}

        <Button isLoading={isLoading} className="mt-8" onClick={() => createToken()}>
          {t("manageUsers_login_tokens_modal_create_btn")}
        </Button>
      </ModalBody>
    </InnerModalWrapper>
  );
};
