import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { Space } from "@eolas-medical/core";
import { Step } from "UIKit";
import SuggestedSpacesForm from "./SuggestedSpacesForm";

interface UseSuggestedSpacesStepProps {
  onNext: () => void;
  suggestedSpaces: Space[];
  isLoading: boolean;
}

export const useSuggestedSpacesStep = ({
  onNext,
  suggestedSpaces,
  isLoading,
}: UseSuggestedSpacesStepProps): Step<"suggestedSpaces"> => {
  const { t } = useTranslation();
  const [hasRequestedAccess, setHasRequestedAccess] = useState(false);

  const handleAccessRequestedChange = useCallback((hasRequested: boolean) => {
    setHasRequestedAccess(hasRequested);
  }, []);

  const nextLabel = hasRequestedAccess
    ? t("complete_profile_continue")
    : t("complete_profile_skip");

  return {
    name: "suggestedSpaces",
    title: t("complete_profile_suggested_spaces_title"),
    subtitle: t("complete_profile_suggested_spaces_subtitle"),
    component: (
      <SuggestedSpacesForm
        suggestedSpaces={suggestedSpaces}
        isLoading={isLoading}
        onNext={onNext}
        onAccessRequestedChange={handleAccessRequestedChange}
      />
    ),
    onSubmit: onNext,
    canProceed: true,
    isLoading: false,
    nextLabel,
    onlySubmitStep: true,
  };
};
