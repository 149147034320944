import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import LZString from "lz-string";
import {
  AnalyticsEvents,
  ContentItem,
  objectIsEolasEditorMetadata,
  sectionStore,
  fromAwsJSON,
  wait,
} from "@eolas-medical/core";
import { transformLinksToOpenInNewTabs } from "Pages/FileViewer/functions/helpers";
import { trackEvent } from "API/Analytics";
import { EolasIFrame } from "UIKit/EolasIFrame/EolasIFrame";
import { useRunOnMountUnmount } from "Hooks";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { ClickSearchBox, IconButton, Text } from "UIKit";
import { CaretDownIcon, CaretUpIcon } from "Assets";

const overrideCss = `
  div, ul, li, table, td {
    width: auto;
    overflow: visible !important;
  }
  img {
    overflow: visible !important
  }  
  .highlight {
    background-color: #FFFFCC
  }
  .current-highlight {
    background-color: #FFBA7C
  }
`;

interface EolasEditorRendererProps {
  contentItem: ContentItem;
}

const highlightJs = `function updateMatchCount(shouldScrollToFirstMatch) {
  const matches = document.querySelectorAll("span.highlight");
  window.parent.postMessage({ type: "matches", count: matches.length }, "*");
  if (shouldScrollToFirstMatch && matches.length) {
    matches[0].scrollIntoView({ behavior: "smooth", block: "center" });
  }
}

function resetMatches() {
  document.querySelectorAll("span.highlight").forEach((span) => {
    const parent = span.parentNode;
    while (span.firstChild) {
      parent?.insertBefore(span.firstChild, span);
    }
    parent?.removeChild(span);
    parent?.normalize();
  });
}


window.addEventListener("message", function (event) {
  switch (event.data.type) {
    case "setHighlights": {
      resetMatches();
      const text = event.data.text;
      const body = document.body;
      const walker = document.createTreeWalker(body, NodeFilter.SHOW_TEXT, null);
      let isFirstMatch = true;
      while (walker.nextNode()) {
        const node = walker.currentNode;
        const parent = node.parentNode;
        if (parent?.classList?.contains?.("highlight")) {
          continue;
        }
        const start = node.nodeValue?.toLowerCase?.().indexOf(text.toLowerCase()) ?? -1;
        const end = start + text.length;
        if (start !== -1) {
          const range = document.createRange();
          range.setStart(node, start);
          range.setEnd(node, end);
          const span = document.createElement("span");
          if (isFirstMatch) {
            span.classList.add("current-highlight");
            isFirstMatch = false;
          }
          span.classList.add("highlight");
          range.surroundContents(span);
        }
      }
      updateMatchCount(true);
      break;
    }
    case "scrollToHighlight": {
      const index = event.data.index;
      const currentHighlights = document.querySelectorAll("span.current-highlight");
      currentHighlights.forEach((currentHighlight) =>
        currentHighlight.classList.remove("current-highlight"),
      );
      const matches = document.querySelectorAll("span.highlight");
      if (matches[index]) {
        matches[index].classList.add("current-highlight");
        matches[index].scrollIntoView({ behavior: "smooth", block: "center" });
      }
      break;
    }
    case "resetMatches": {
      resetMatches();
      break;
    }
  }
});
`;

export const EolasEditorRenderer: React.FC<EolasEditorRendererProps> = ({ contentItem }) => {
  const { t } = useTranslation();
  const [searchTerm, setSearchTerm] = useState(localSearchStore.textToHighlight);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);
  const [matches, setMatches] = useState<number>(0);
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  const handleClickSearch = (text: string) => {
    if (text.trim() !== searchTerm.trim()) {
      resetSearch();
    }
    iframeRef.current?.contentWindow?.postMessage({ type: "setHighlights", text }, "*");
    setSearchTerm(text);
  };
  const htmlContent = useMemo(() => {
    if (!contentItem?.metadata) {
      return null;
    }

    const metadata = fromAwsJSON(contentItem.metadata);
    if (!objectIsEolasEditorMetadata(metadata)) {
      return null;
    }

    const decodedContent = LZString.decompressFromEncodedURIComponent(metadata.eolasEditorContent);
    if (!decodedContent) {
      return null;
    }

    return transformLinksToOpenInNewTabs(decodedContent);
  }, [contentItem]);

  const handleMessage = useCallback((event: MessageEvent) => {
    if (event.data.type === "matches") {
      setMatches(event.data.count);
    }
  }, []);
  useEffect(() => {
    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleMessage]);

  const scrollToMatch = (index: number) => {
    iframeRef.current?.contentWindow?.postMessage({ type: "scrollToHighlight", index }, "*");
  };

  const handleNextMatch = () => {
    // Ensure index does not exceed the last match
    const newIndex = Math.min(currentIndex + 1, matches - 1);
    setCurrentIndex(newIndex);
    scrollToMatch(newIndex);
  };

  const handlePrevMatch = () => {
    // Ensure index does not go below 0
    const newIndex = Math.max(currentIndex - 1, 0);
    setCurrentIndex(newIndex);
    scrollToMatch(newIndex);
  };

  useRunOnMountUnmount({
    onMount: async () => {
      if (searchTerm) {
        // If search term is already set on mount, it means it has been set from a previous search via localSearchStore.textToHighlight
        // Need to wait as iFrame seems to not be ready for postMessage straight away
        await wait(200);
        handleClickSearch(searchTerm);
      }

      const mainSectionType = contentItem.mainSectionId
        ? sectionStore.getMainSectionTypeFromMainSectionID(contentItem.mainSectionId)
        : null;

      const mainSectionIdentity = contentItem.mainSectionId
        ? sectionStore.getMainSectionIdentityByMainSectionId(contentItem.mainSectionId)
        : null;

      // the order is important here as if the mainSection is null, we want to use the mainSectionIdentity
      const idForTracking = mainSectionType ?? mainSectionIdentity;

      if (idForTracking) {
        trackEvent(AnalyticsEvents.OPEN_FILE, {
          mainSectionId: idForTracking,
          fileId: contentItem.id,
          fileType: contentItem.type || "",
          fileName: contentItem.name,
          sectionId: contentItem.parentId,
        });
      }
    },
  });

  if (!htmlContent) {
    return <div>{t("error_loading_content")}</div>;
  }

  const resetSearch = (shouldClearHighlights?: boolean) => {
    setSearchTerm("");
    setMatches(0);
    setCurrentIndex(0);
    if (shouldClearHighlights) {
      iframeRef.current?.contentWindow?.postMessage({ type: "resetMatches" }, "*");
    }
  };

  return (
    <div className="rounded-lg p-4 bg-white" style={{ marginTop: 10 }}>
      <SearchComponent
        resetSearch={resetSearch}
        isSearchActive={searchTerm.length > 0}
        handleClickSearch={handleClickSearch}
        handleNextMatch={handleNextMatch}
        handlePrevMatch={handlePrevMatch}
        currentIndex={currentIndex}
        totalMatchCount={matches}
      />
      <EolasIFrame
        ref={iframeRef}
        internalHtmlContent={htmlContent}
        overrideCss={overrideCss}
        additionalJs={highlightJs}
        overrideContainerClass="w-full flex flex-grow border-0 bg-white h-60vh lg:h-70vh"
      />
    </div>
  );
};

const SearchComponent = ({
  handleClickSearch,
  handleNextMatch,
  handlePrevMatch,
  currentIndex,
  totalMatchCount,
  isSearchActive,
  resetSearch,
}: {
  handleClickSearch: (text: string) => void;
  handleNextMatch: () => void;
  handlePrevMatch: () => void;
  resetSearch: (shouldClearHighlights?: boolean) => void;
  currentIndex: number;
  totalMatchCount: number;
  isSearchActive: boolean;
}) => {
  const { t } = useTranslation();
  const [searchInput, setSearchInput] = useState(localSearchStore.textToHighlight);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && totalMatchCount > 0) {
        event.preventDefault();
        handleNextMatch();
      }
    };

    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [totalMatchCount, handleNextMatch]);

  const handleClearSearch = () => {
    setSearchInput("");
    resetSearch(true);
  };

  return (
    <div className="flex flex-col mb-2">
      <div className="flex flex-col gap-3 z-0">
        <ClickSearchBox
          placeholder="Search within this document..."
          searchBoxSize="md"
          onChangeText={(text) => setSearchInput(text)}
          value={searchInput}
          onClickSearch={() => handleClickSearch(searchInput)}
          onClearSearch={handleClearSearch}
          className="z-20 w-full"
          inputClassName="placeholder-grey-600 placeholder:font-semibold"
          iconClassName="black"
        />
        {totalMatchCount > 0 ? (
          <div className="flex justify-center gap-2 items-center">
            <IconButton
              size="sm"
              type="button"
              icon={<CaretUpIcon />}
              variant="rounded"
              onClick={handlePrevMatch}
            />
            <Text level={1}>
              {currentIndex + 1}/{totalMatchCount}
            </Text>
            <IconButton
              size="sm"
              type="button"
              icon={<CaretDownIcon />}
              variant="rounded"
              onClick={handleNextMatch}
            />
          </div>
        ) : null}
      </div>
      {isSearchActive && totalMatchCount === 0 ? (
        <Text level={1} className="text-red-500 text-center mt-2">
          {t("no_results")}
        </Text>
      ) : null}
    </div>
  );
};
