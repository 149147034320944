import { useState } from "react";
import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import ProfessionalVerificationForm from "./ProfessionalVerificationForm";
import { FORM_FIELDS } from "../../constants/formFields";

interface UseProfessionalVerificationStepProps {
  onSubmit: (verification: { professionalBody: string; registrationNumber: string }) => void;
  isUKIrelandUser: boolean;
  isLoading: boolean;
  backgroundColor?: string;
}

interface ProfessionalVerificationFormValues {
  professionalBody: string;
  registrationNumber: string;
}

export const useProfessionalVerificationStep = ({
  onSubmit,
  isUKIrelandUser,
  isLoading,
  backgroundColor,
}: UseProfessionalVerificationStepProps): Step<typeof FORM_FIELDS.PROFESSIONAL_VERIFICATION> => {
  const { t } = useTranslation();
  const [isCustomInputMode, setIsCustomInputMode] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const ukIrelandSchema = yup.object({
    professionalBody: yup.string().test({
      name: "custom-pending-validation",
      message: t("complete_profile_verification_professional_body_required"),
      test: function (value) {
        if (value === "CUSTOM_PENDING") {
          return false;
        }
        return !!value;
      },
    }),
    registrationNumber: yup.string().when("professionalBody", {
      is: (value: string) => value !== "NOT_APPLICABLE",
      then: yup.string().required(t("complete_profile_verification_registration_number_required")),
      otherwise: yup.string().notRequired(),
    }),
  });

  const nonUKIrelandSchema = yup.object({
    professionalBody: yup
      .string()
      .required(t("complete_profile_verification_professional_body_required")),
    registrationNumber: yup.string().when("professionalBody", {
      is: (value: string) => value !== "NOT_APPLICABLE",
      then: yup.string().required(t("complete_profile_verification_registration_number_required")),
      otherwise: yup.string().notRequired(),
    }),
  });

  const schema = isUKIrelandUser ? ukIrelandSchema : nonUKIrelandSchema;

  const { control, handleSubmit, formState, setValue, trigger } =
    useForm<ProfessionalVerificationFormValues>({
      defaultValues: {
        professionalBody: "",
        registrationNumber: "",
      },
      resolver: yupResolver(schema),
      mode: "onChange",
    });

  const handleProfessionalBodySelect = (body: string) => {
    setSubmitError(null);

    if (body === "CUSTOM_PENDING") {
      setIsCustomInputMode(true);
    } else {
      setIsCustomInputMode(false);
    }

    setValue("professionalBody", body, { shouldValidate: true });

    if (body === "NOT_APPLICABLE") {
      setValue("registrationNumber", "", { shouldValidate: true });
    }

    trigger();
  };

  const handleCustomInputChange = (value: string) => {
    setValue("professionalBody", value, { shouldValidate: true });
    setSubmitError(null);

    if (value && value !== "CUSTOM_PENDING") {
      setIsCustomInputMode(true);
    }

    trigger();
  };

  const handleVerificationSubmit = async (data: ProfessionalVerificationFormValues) => {
    if (data.professionalBody === "CUSTOM_PENDING") {
      return;
    }

    try {
      setSubmitError(null);
      await onSubmit({
        professionalBody: data.professionalBody,
        registrationNumber: data.registrationNumber,
      });
    } catch (error) {
      setSubmitError(error instanceof Error ? error.message : "Failed to update profile");
    }
  };

  return {
    name: FORM_FIELDS.PROFESSIONAL_VERIFICATION,
    title: t("complete_profile_verification_title"),
    subtitle: t("complete_profile_verification_subtitle"),
    component: (
      <ProfessionalVerificationForm
        control={control}
        isUKIrelandUser={isUKIrelandUser}
        onSelectProfessionalBody={handleProfessionalBodySelect}
        onCustomInputChange={handleCustomInputChange}
        isCustomInputMode={isCustomInputMode}
        backgroundColor={backgroundColor}
      />
    ),
    onSubmit: handleSubmit(handleVerificationSubmit),
    canProceed: formState.isValid && !submitError,
    isLoading: formState.isSubmitting || isLoading,
    submitError: Boolean(submitError) || !formState.isValid,
    subtitleClassName: "text-left",
  };
};
