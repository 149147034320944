import { hasProp, isDefined, sectionStore } from "@eolas-medical/core";
import { useGetFiles } from "Pages/Spaces/hooks/useGetFiles";
import { useMemo } from "react";

import { fromAwsJSON, parseAwsJson } from "Utilities";
import { EolasChecklist } from "Utilities/types";

export const useGetCompletedChecklists = (checklistID: string) => {
  const checklist = sectionStore.getSection(checklistID);
  const { metadata, childrenOrder: maybeChildrenOrder } = checklist;
  const { disabled } = sectionStore.getChildReferenceOfSection(checklistID) ?? {};
  const { checklistTemplate }: { checklistTemplate: EolasChecklist } =
    typeof metadata === "string" ? fromAwsJSON(metadata ?? "{}") : metadata;

  const childrenOrder = useMemo(() => {
    return (maybeChildrenOrder ?? []).filter(isDefined);
  }, [maybeChildrenOrder]);

  const { files } = useGetFiles({ childrenOrder });

  const completedChecklists = useMemo(() => {
    return files.map((file) => {
      const parsed = parseAwsJson(file.metadata);
      if (hasProp(parsed, "completedChecklist")) {
        return { ...file, metadata: parsed.completedChecklist };
      }
      return file;
    });
  }, [files]);

  const hasIssues = completedChecklists.some(
    (checklistFile) =>
      hasProp(checklistFile.metadata, "checklistIssues") && checklistFile.metadata.checklistIssues,
  );

  return {
    hasIssues,
    checklist,
    completedChecklists,
    isDisabled: disabled === "true",
    checklistName: checklistTemplate?.checklistName ?? "Checklist",
    files,
  };
};
