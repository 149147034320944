import { FormElement, Input, Textarea } from "UIKit";
import { useTranslation } from "react-i18next";
import { CheckIcon } from "Assets";
import { Control, useWatch } from "react-hook-form";

interface LocationBasedFormInputsProps {
  control: Control<{
    spaceName: string;
    organisationName?: string;
    description: string;
    location: {
      label: string;
      value: string;
    };
    referralSource: {
      label: string;
      value: string;
    };
  }>;
  isNameValid?: boolean;
}

export const LocationBasedFormInputs = ({ control, isNameValid }: LocationBasedFormInputsProps) => {
  const { t } = useTranslation();

  // Use useWatch to always get the latest location value
  const selectedLocation = useWatch({ control, name: "location" });
  const isUKLocation = selectedLocation?.value?.toLowerCase().includes(",uk");

  if (!selectedLocation?.value) {
    return null;
  }

  return (
    <>
      <FormElement
        required
        id="spaceName"
        control={control}
        label={t(isUKLocation ? "space_create_uk_name" : "space_create_name")}
        labelClassName="text-base"
      >
        {(fieldProps) => {
          const { value, ...rest } = fieldProps;
          return (
            <Input
              {...rest}
              autoFocus={true}
              size="md"
              iconRight={isNameValid ? <CheckIcon className="text-green" /> : undefined}
              placeholder={t("edit_space_details_name_placeholder")}
              className="text-base resize bg-white rounded-md"
              value={value}
            />
          );
        }}
      </FormElement>

      {isUKLocation ? (
        <FormElement
          id="organisationName"
          control={control}
          label={t("selectSpace_create_org_name_label")}
          labelClassName="text-base"
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Input
                {...rest}
                size="md"
                iconRight={isNameValid ? <CheckIcon className="text-green" /> : undefined}
                placeholder={t("edit_space_details_name_placeholder")}
                className="text-base resize bg-white rounded-md"
                value={value}
              />
            );
          }}
        </FormElement>
      ) : (
        <FormElement
          id="description"
          control={control}
          label={t("space_create_description")}
          labelClassName="text-base"
        >
          {(fieldProps) => {
            const { value, ...rest } = fieldProps;
            return (
              <Textarea
                {...rest}
                placeholder={t("edit_space_details_description_placeholder")}
                value={value}
                className="resize-none"
                characterLimit={350}
                spellCheck
                maxLength={350}
              />
            );
          }}
        </FormElement>
      )}
    </>
  );
};
