import React from "react";
import { useTranslation } from "react-i18next";
import { success } from "Assets";
import Lottie from "react-lottie-player";
import { Button, InnerModalWrapper, Text, Title } from "UIKit";

interface AccessRequestSuccessScreenProps {
  spaceName: string;
  onClose?: () => void;
  onRequestComplete?: () => void;
}

const AccessRequestSuccessScreen = ({
  spaceName,
  onClose,
  onRequestComplete,
}: AccessRequestSuccessScreenProps) => {
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (onRequestComplete) {
      onRequestComplete();
    }

    if (onClose) {
      onClose();
    }
  };

  return (
    <InnerModalWrapper>
      <div className="h-65vh overflow-y-auto">
        <div className="p-8 flex flex-col items-center justify-center text-center">
          <Lottie
            style={{ width: "16rem", height: "16rem" }}
            loop={false}
            play
            animationData={success}
          />
          <Title level={5} className="mb-4">
            {t("selectSpace_request_success_title")}
          </Title>
          <Text level={1} className="mb-8 max-w-md">
            {t("selectSpace_request_success_message", { spaceName })}
          </Text>
          <Text level={2} className="mb-8 text-gray-600 max-w-md">
            {t("selectSpace_request_success_description")}
          </Text>
          <Button size="md" onClick={handleButtonClick}>
            <Text level={1} className="text-white">
              {t("selectSpace_request_success_button")}
            </Text>
          </Button>
        </div>
      </div>
    </InnerModalWrapper>
  );
};

export default AccessRequestSuccessScreen;
