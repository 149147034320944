import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormElement } from "UIKit";
import useGetLocations from "Hooks/useGetLocations";
import { formatLocation } from "Utilities/helpers";
import { EnhancedLocationSelect } from "./EnhancedLocationSelect";

interface LocationFormProps {
  control: Control<{
    location:
      | {
          label: string;
          value: string;
        }
      | undefined;
  }>;
}

const LocationForm = ({ control }: LocationFormProps) => {
  const { t } = useTranslation();
  const { getLocations } = useGetLocations();

  const handleFetchLocations = async (query: string) => {
    const locations = await getLocations(query);
    return locations.map((location) => formatLocation({ location }));
  };

  return (
    <div className="w-full">
      <div className="flex flex-col justify-center">
        <div className="w-full mt-8">
          <FormElement
            labelClassName="text-base"
            required
            id="location"
            control={control}
            label={t("profile_location_label")}
          >
            {(fieldProps) => (
              <EnhancedLocationSelect
                {...fieldProps}
                loadOptions={handleFetchLocations}
                placeholder={t("profile_location_placeholder")}
                isSearchable
                className="location-select"
                classNamePrefix="location-select"
                menuPlacement="bottom"
              />
            )}
          </FormElement>
        </div>
        <div className="h-40" />
      </div>
    </div>
  );
};

export default LocationForm;
