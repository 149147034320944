import { eolasLogger } from "@eolas-medical/core";

export type DownloadCSVNotification = "success" | "failure";

export const escapeCsvValue = (value: string) => {
  // eslint-disable-next-line quotes
  if (value.includes(",") || value.includes("\n") || value.includes('"')) {
    // eslint-disable-next-line quotes
    return `"${value.replace(/"/g, '""')}"`;
  }
  return value;
};

export const downloadCSV = (
  csvContent: string,
  fileName: string,
  showNotification: (outcome: DownloadCSVNotification) => void,
  errorMessage: string = "Failed to export CSV",
): void => {
  try {
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    URL.revokeObjectURL(url);
    document.body.removeChild(link);

    showNotification("success");
  } catch {
    eolasLogger.error(new Error(errorMessage));
    showNotification("failure");
  }
};
