import { format } from "date-fns";
import { SpaceUser } from "clients/departments/types";
import { useNotifications } from "Components/Notifications";
import { useTranslation } from "react-i18next";
import { downloadCSV, escapeCsvValue } from "Utilities/csv";

const NOT_AVAILABLE = "Not available";

const getAccessLevel = (user: SpaceUser) => {
  if (user.accessLevel === "admin") {
    if (user.limitedAccess?.length) {
      return "Limited Admin";
    }
    return "Full Admin";
  }
  return "Standard";
};

const generateCSVContent = (users: SpaceUser[]) => {
  const headers = [
    "First Name",
    "Family Name",
    "Email",
    "Standard/Limited Admin/Full Admin",
    "Created At",
  ];

  const rows = users.map((user) => [
    escapeCsvValue(user.user?.given_name || NOT_AVAILABLE),
    escapeCsvValue(user.user?.family_name || NOT_AVAILABLE),
    escapeCsvValue(user.user?.email || NOT_AVAILABLE),
    escapeCsvValue(getAccessLevel(user)),
    escapeCsvValue(format(new Date(user.createdAt), "dd MMMM yyyy")),
  ]);

  const csvContent = [headers.join(","), ...rows.map((row) => row.join(","))].join("\n");

  return csvContent;
};

export const useExportUserCsv = () => {
  const { showNotification } = useNotifications();
  const { t } = useTranslation();
  const handleExportUsers = (users: SpaceUser[], spaceOrgName?: string) => {
    const csvContent = generateCSVContent(users);
    const fileName = spaceOrgName ? `User List ${spaceOrgName}.csv` : "User List.csv";
    downloadCSV(
      csvContent,
      fileName,
      (outcome) => {
        showNotification({
          type: outcome === "success" ? "success" : "error",
          description:
            outcome === "success" ? t("export_users_success") : t("export_users_failure"),
        });
      },
      "Failed to export user CSV",
    );
  };

  return { handleExportUsers };
};
