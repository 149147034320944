import { StylesConfig, CSSObjectWithLabel, GroupBase } from "react-select";
import { theme } from "UIKit/theme";

export const createSelectStyles = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  isMulti: boolean = false,
  variant: "default" | "flag" = "default",
): StylesConfig<Option, IsMulti, Group> => ({
  control: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    borderRadius: "8px",
    boxShadow: "none",
    borderColor: theme.colors.grey[300],
    backgroundColor: theme.colors.white,
    "&:hover": {
      borderColor: theme.colors.blue[500],
    },
    fontSize: "16px",
    padding: "2px",
  }),
  dropdownIndicator: (baseStyles: CSSObjectWithLabel, state) => ({
    ...baseStyles,
    color: theme.colors.blue[500],
    transform: state.selectProps.menuIsOpen ? "rotate(180deg)" : "rotate(0)",
    transition: "250ms",
    "&:hover": {
      color: theme.colors.blue[500],
    },
  }),
  singleValue: (baseStyles: CSSObjectWithLabel) => {
    // Base styles that apply to all variants
    const commonStyles = {
      ...baseStyles,
      color: theme.colors.grey[800],
    };

    // Default variant (colored background, left margin for cursor visibility)
    if (variant === "default") {
      return {
        ...commonStyles,
        backgroundColor: theme.colors.blue[100],
        color: theme.colors.blue[500],
        borderRadius: "6px",
        padding: "2px 8px",
        margin: "4px 6px 4px 8px", // Added left margin for typing indicator
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
        fontWeight: 500,
        position: "relative",
        width: "fit-content",
      };
    }

    // Flag variant (for components that need flag support)
    return {
      ...commonStyles,
      color: theme.colors.grey[800],
      display: "flex",
      alignItems: "center",
      "& .flag": {
        marginRight: "8px",
      },
    };
  },
  multiValue: !isMulti
    ? undefined
    : (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        backgroundColor: theme.colors.blue[100],
        color: theme.colors.blue[500],
        borderRadius: "6px",
        padding: "2px 4px",
        margin: "4px 6px 4px 0",
        boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
      }),
  multiValueLabel: !isMulti
    ? undefined
    : (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        color: theme.colors.blue[500],
        fontWeight: 500,
        padding: "2px 6px",
      }),
  multiValueRemove: !isMulti
    ? undefined
    : (baseStyles: CSSObjectWithLabel) => ({
        ...baseStyles,
        color: theme.colors.blue[500],
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: theme.colors.blue[500],
          color: theme.colors.white,
        },
      }),
  option: (baseStyles: CSSObjectWithLabel, state) => ({
    ...baseStyles,
    padding: "12px 16px",
    borderRadius: "6px",
    margin: "4px 0",
    backgroundColor: state.isSelected
      ? theme.colors.blue[500]
      : state.isFocused
      ? theme.colors.blue[100]
      : theme.colors.white,
    color: state.isSelected ? theme.colors.white : theme.colors.grey[800],
    fontWeight: state.isSelected ? 500 : 400,
    "&:hover": {
      backgroundColor: state.isSelected ? theme.colors.blue[500] : theme.colors.blue[100],
    },
  }),
  menu: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    width: "auto",
    minWidth: "100%",
    zIndex: 9999,
    borderRadius: "8px",
    boxShadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
    border: `1px solid ${theme.colors.grey[200]}`,
  }),
  menuList: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    maxHeight: "250px", // Using the smaller height from EnhancedSelectStyles
    padding: "8px",
  }),
  placeholder: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: theme.colors.grey[500],
  }),
  input: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: theme.colors.grey[800],
  }),
  clearIndicator: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: theme.colors.grey[500],
    "&:hover": {
      color: theme.colors.blue[500],
    },
  }),
  loadingIndicator: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: theme.colors.blue[500],
  }),
  loadingMessage: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: theme.colors.grey[800],
    padding: "12px 16px",
  }),
  noOptionsMessage: (baseStyles: CSSObjectWithLabel) => ({
    ...baseStyles,
    color: theme.colors.grey[800],
    padding: "12px 16px",
  }),
});
