import React, { useState, useCallback, FormEvent } from "react";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormElement, Input } from "UIKit";
import { ProfessionalBody } from "Pages/MeTab/pages/MyProfile/components/ProfileVerificationModal/ProfileVerificationModal";
import { ProfessionalBodyLabel } from "Pages/MeTab/types";
import {
  EnhancedProfessionalBodySelect,
  ProfessionalBodyOption,
} from "./EnhancedProfessionalBodySelect";

interface ProfessionalVerificationFormValues {
  professionalBody: string;
  registrationNumber: string;
}

interface ProfessionalVerificationFormProps {
  control: Control<ProfessionalVerificationFormValues>;
  isUKIrelandUser: boolean;
  onSelectProfessionalBody: (body: string) => void;
  onCustomInputChange?: (value: string) => void;
  isCustomInputMode?: boolean;
  backgroundColor?: string;
}

const ProfessionalVerificationForm = ({
  control,
  isUKIrelandUser,
  onSelectProfessionalBody,
  onCustomInputChange,
  isCustomInputMode = false,
  backgroundColor,
}: ProfessionalVerificationFormProps) => {
  const { t } = useTranslation();
  const [showCustomInput, setShowCustomInput] = useState<boolean>(isCustomInputMode);
  const [customOptionSelected, setCustomOptionSelected] = useState<boolean>(isCustomInputMode);

  const {
    field: { onChange: onChangeProfessionalBody, value: professionalBodyValue },
    formState: { errors },
  } = useController({
    name: "professionalBody",
    control,
  });

  const professionalBodyOptions: ProfessionalBodyOption[] = [
    {
      value: ProfessionalBody.GENERAL_MEDICAL_COUNCIL,
      label: ProfessionalBodyLabel[ProfessionalBody.GENERAL_MEDICAL_COUNCIL],
      flag: "🇬🇧",
    },
    {
      value: ProfessionalBody.NURSING_AND_MIDWIFERY_COUNCIL,
      label: ProfessionalBodyLabel[ProfessionalBody.NURSING_AND_MIDWIFERY_COUNCIL],
      flag: "🇬🇧",
    },
    {
      value: ProfessionalBody.GENERAL_PHARMACEUTICAL_COUNCIL,
      label: ProfessionalBodyLabel[ProfessionalBody.GENERAL_PHARMACEUTICAL_COUNCIL],
      flag: "🇬🇧",
    },
    {
      value: ProfessionalBody.IRISH_MEDICAL_COUNCIL,
      label: ProfessionalBodyLabel[ProfessionalBody.IRISH_MEDICAL_COUNCIL],
      flag: "🇮🇪",
    },
    {
      value: ProfessionalBody.NURSING_AND_MIDWIFERY_BOARD_IRELAND,
      label: ProfessionalBodyLabel[ProfessionalBody.NURSING_AND_MIDWIFERY_BOARD_IRELAND],
      flag: "🇮🇪",
    },
    {
      value: ProfessionalBody.PHARMACEUTICAL_SOCIETY_OF_IRELAND,
      label: ProfessionalBodyLabel[ProfessionalBody.PHARMACEUTICAL_SOCIETY_OF_IRELAND],
      flag: "🇮🇪",
    },
    {
      value: "NOT_APPLICABLE",
      label: t("complete_profile_verification_not_applicable"),
      isNotApplicable: true,
    },
  ];

  const customOption: ProfessionalBodyOption = {
    value: "CUSTOM",
    label: t("complete_profile_verification_choose_different_body"),
    isCustom: true,
  };

  const handleCustomOptionSelected = useCallback((): void => {
    setShowCustomInput(true);
    setCustomOptionSelected(true);
    onChangeProfessionalBody("CUSTOM_PENDING");
    onSelectProfessionalBody("CUSTOM_PENDING");

    if (onCustomInputChange) {
      onCustomInputChange("CUSTOM_PENDING");
    }
  }, [onChangeProfessionalBody, onSelectProfessionalBody, onCustomInputChange]);

  const handleProfessionalBodyChange = useCallback(
    (newValue: ProfessionalBodyOption | null): void => {
      if (!newValue) return;

      if (newValue.isCustom) {
        handleCustomOptionSelected();
      } else if (newValue.isNotApplicable) {
        setShowCustomInput(false);
        setCustomOptionSelected(false);
        onChangeProfessionalBody("NOT_APPLICABLE");
        onSelectProfessionalBody("NOT_APPLICABLE");
      } else {
        setShowCustomInput(false);
        setCustomOptionSelected(false);
        onChangeProfessionalBody(newValue.value);
        onSelectProfessionalBody(newValue.value);
      }
    },
    [onChangeProfessionalBody, onSelectProfessionalBody, handleCustomOptionSelected],
  );

  const handleCustomInputChange = useCallback(
    (e: FormEvent<HTMLInputElement>): void => {
      const value = e.currentTarget.value;
      onChangeProfessionalBody(value);
      onSelectProfessionalBody(value);

      if (onCustomInputChange) {
        onCustomInputChange(value);
      }
    },
    [onChangeProfessionalBody, onSelectProfessionalBody, onCustomInputChange],
  );

  const selectedOption = customOptionSelected
    ? customOption
    : professionalBodyOptions.find((option) => option.value === professionalBodyValue) || null;

  const showRegistrationNumber =
    (selectedOption && !selectedOption.isNotApplicable) || showCustomInput || !isUKIrelandUser;

  const professionalBodyError =
    typeof errors?.professionalBody?.message === "string"
      ? errors.professionalBody.message
      : undefined;

  const renderUKIrelandForm = (): JSX.Element => (
    <>
      <FormElement
        labelClassName="text-base"
        required
        id="professionalBody"
        control={control}
        label={t("complete_profile_verification_professional_body_label")}
        errorMessage={showCustomInput ? undefined : professionalBodyError}
      >
        {(fieldProps) => (
          <div>
            <EnhancedProfessionalBodySelect
              {...fieldProps}
              options={professionalBodyOptions}
              value={selectedOption}
              onChange={(newValue) =>
                handleProfessionalBodyChange(newValue as ProfessionalBodyOption)
              }
              placeholder={t("complete_profile_verification_select_professional_body")}
              className="professional-body-select"
              classNamePrefix="professional-body-select"
              onCustomOptionSelected={handleCustomOptionSelected}
              menuPlacement="bottom"
            />
          </div>
        )}
      </FormElement>

      {showCustomInput ? (
        <FormElement
          labelClassName="text-base"
          required
          id="customProfessionalBody"
          label={t("complete_profile_verification_custom_professional_body_label")}
          errorMessage={professionalBodyError}
          control={control}
        >
          {(fieldProps) => (
            <Input
              id={fieldProps.id}
              className="input pl-4 box-content text-base rounded-md"
              size="md"
              placeholder={t("complete_profile_verification_enter_professional_body")}
              value={professionalBodyValue === "CUSTOM_PENDING" ? "" : professionalBodyValue}
              onChange={handleCustomInputChange}
            />
          )}
        </FormElement>
      ) : null}

      {showRegistrationNumber ? (
        <FormElement
          labelClassName="text-base"
          required
          id="registrationNumber"
          control={control}
          label={t("complete_profile_verification_registration_number_label")}
        >
          {(fieldProps) => (
            <Input
              {...fieldProps}
              size="md"
              className="input pl-4 box-content text-base rounded-md"
              placeholder={t("complete_profile_verification_enter_registration_number")}
            />
          )}
        </FormElement>
      ) : null}
    </>
  );

  const renderNonUKIrelandForm = (): JSX.Element => (
    <>
      <FormElement
        labelClassName="text-base"
        required
        id="professionalBody"
        control={control}
        label={t("complete_profile_verification_professional_body_label")}
      >
        {(fieldProps) => (
          <Input
            {...fieldProps}
            size="md"
            className="input pl-4 box-content text-base rounded-md"
            placeholder={t("complete_profile_verification_enter_professional_body")}
          />
        )}
      </FormElement>

      <FormElement
        labelClassName="text-base"
        required
        id="registrationNumber"
        control={control}
        label={t("complete_profile_verification_registration_number_label")}
      >
        {(fieldProps) => (
          <Input
            {...fieldProps}
            size="md"
            className="input pl-4 box-content text-base rounded-md"
            placeholder={t("complete_profile_verification_enter_registration_number")}
          />
        )}
      </FormElement>
    </>
  );

  return (
    <div className={`w-full${backgroundColor ? ` ${backgroundColor}` : ""}`}>
      <div className="flex flex-col justify-center">
        <div className="w-full mt-2 xl:mt-8">
          {isUKIrelandUser ? renderUKIrelandForm() : renderNonUKIrelandForm()}
        </div>
        <div className="h-32 2xl:h-68" />
      </div>
    </div>
  );
};

export default ProfessionalVerificationForm;
