import React from "react";
import { EnhancedAsyncSelect, EnhancedAsyncSelectProps } from "../../shared/EnhancedAsyncSelect";

export type EnhancedLocationSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
> = EnhancedAsyncSelectProps<Option, IsMulti> & {
  onMenuOpen?: () => void;
};

export const EnhancedLocationSelect = <Option = unknown, IsMulti extends boolean = false>(
  props: EnhancedLocationSelectProps<Option, IsMulti>,
) => {
  return <EnhancedAsyncSelect<Option, IsMulti> {...props} />;
};
