import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, InnerModalWrapper } from "UIKit";
import {
  ConditionsAcceptedResponse,
  ConditionsAcceptedType,
  eolasLogger,
  getObjectEntries,
  mergeAgreements,
  profilesClient,
  TERMS_URLS,
  userStore,
} from "@eolas-medical/core";
import { CheckIcon } from "Assets";
import { modalStore } from "Stores/ModalStore";
import { makeUseGetDisplayTsAndCsStatusKey } from "../hooks/useGetDisplayTsAndCsStatus";

export const TnCModal = observer(
  ({
    acceptedVersions,
    latestPolicyVersions,
  }: Omit<ConditionsAcceptedResponse, "shouldDisplay">) => {
    const { t } = useTranslation();

    const { userData } = userStore;

    const queryClient = useQueryClient();

    const [acceptedVersionsState, updateAcceptedVersions] = useState<{
      [ConditionsAcceptedType.eula]: boolean;
      [ConditionsAcceptedType.privacyPolicy]: boolean;
      [ConditionsAcceptedType.termsOfService]: boolean;
    }>({
      [ConditionsAcceptedType.eula]: Boolean(acceptedVersions[ConditionsAcceptedType.eula]),
      [ConditionsAcceptedType.privacyPolicy]: Boolean(
        acceptedVersions[ConditionsAcceptedType.privacyPolicy],
      ),
      [ConditionsAcceptedType.termsOfService]: Boolean(
        acceptedVersions[ConditionsAcceptedType.termsOfService],
      ),
    });

    const { mutate: updateTnC, isLoading } = useMutation({
      mutationKey: ["updateTnC", userStore.userID],
      mutationFn: async () => {
        const { conditionsAccepted } = mergeAgreements(userStore.userData, {
          [ConditionsAcceptedType.eula]: [
            { dateAccepted: new Date().toISOString(), version: latestPolicyVersions.eula },
          ],
          [ConditionsAcceptedType.privacyPolicy]: [
            { dateAccepted: new Date().toISOString(), version: latestPolicyVersions.privacyPolicy },
          ],
          [ConditionsAcceptedType.termsOfService]: [
            {
              dateAccepted: new Date().toISOString(),
              version: latestPolicyVersions.termsOfService,
            },
          ],
        });
        const updatedProfile = await profilesClient.updateUserProfile(userStore.userID, {
          conditionsAccepted,
        });
        userStore.updateData({ user: updatedProfile });
        modalStore.closeModal();
        queryClient.invalidateQueries(makeUseGetDisplayTsAndCsStatusKey(userStore.userID));
      },
      onError: (error) => {
        eolasLogger.error(error, { info: "Error updating profile with ToCs" });
        queryClient.setQueryData(makeUseGetDisplayTsAndCsStatusKey(userStore.userID), () => ({
          shouldDisplay: false,
          acceptedVersions: {},
          latestPolicyVersions: {
            eula: "",
            privacyPolicy: "",
            termsOfService: "",
          },
        }));
        modalStore.closeModal();
      },
    });

    const shouldShowUpdatedTerms =
      Boolean(userData.completedTour) || Boolean(userData.conditionsAccepted);

    const lookup = {
      [ConditionsAcceptedType.eula]: t("departmentTour_TC_confirmDisclaimer"),
      [ConditionsAcceptedType.privacyPolicy]: t("departmentTour_TC_confirmPrivacyPolicy"),
      [ConditionsAcceptedType.termsOfService]: t("departmentTour_TC_confirmTC"),
    };

    return (
      <InnerModalWrapper>
        <div className="overflow-y-auto" style={{ maxHeight: "80vh" }}>
          <div className="flex flex-col py-8 px-4 sm:px-20 space-y-6">
            <p data-testid="welcomeModalTitle" className="text-center text-lg">
              {shouldShowUpdatedTerms
                ? t("departmentTour_TC_modal_title_update")
                : t("departmentTour_TC_modal_title")}
            </p>

            <span>
              {shouldShowUpdatedTerms
                ? t("departmentTour_TC_modal_description_updated")
                : t("departmentTour_TC_modal_description")}
              <span className="whitespace-pre-wrap">
                {t("departmentTour_TC_modal_popups_bold")}
              </span>
              <span className="whitespace-pre-wrap">
                {t("departmentTour_TC_modal_popups_desc")}
              </span>
            </span>

            <div className="flex flex-col space-y-2">
              <p className="mb-4">
                {shouldShowUpdatedTerms
                  ? t("departmentTour_TC_modal_our_policies_updated")
                  : t("departmentTour_TC_modal_our_policies")}
              </p>
              {getObjectEntries(lookup).map(([type, text]) => {
                if (acceptedVersions[type]) {
                  return null;
                }
                return (
                  <div
                    key={type}
                    className="flex flex-row space-x-1"
                    onClick={() =>
                      updateAcceptedVersions({
                        ...acceptedVersionsState,
                        [type]: !acceptedVersionsState[type],
                      })
                    }
                  >
                    {acceptedVersionsState[type] ? (
                      <CheckIcon className="text-green w-6 h-6" />
                    ) : (
                      <span className="w-6 h-6 border border-grey-400 bg-grey-300 rounded-full" />
                    )}
                    <span className="text-black-500 font-semibold ml-2">
                      {t("departmentTour_TC_modal_i_accept")}
                    </span>
                    <a
                      className="text-blue-500 hover:underline font-semibold"
                      href={TERMS_URLS[type]}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {text}
                    </a>
                  </div>
                );
              })}
            </div>
            <Button
              onClick={() => updateTnC()}
              color="blue"
              type="submit"
              isLoading={isLoading}
              data-testid="TnCModalSubmit"
              disabled={Object.values(acceptedVersionsState).some((accepted) => !accepted)}
              className="self-center w-full sm:w-1/3 "
            >
              {t("departmentTour_TC_button")}
            </Button>
          </div>
        </div>
      </InnerModalWrapper>
    );
  },
);
