import { observer } from "mobx-react-lite";

import { useHistory } from "react-router-dom";
import { userStore } from "@eolas-medical/core";

import { AuthenticationRoutes, MainRouter } from "./Routes";
import { isDev } from "Utilities/helpers";
import { usePostLoginRedirect } from "shared/hooks/usePostLoginRedirect/usePostLoginRedirect";
import React from "react";
import { webStore } from "Stores/WebStore";
import useShouldCreateSpace from "Pages/Spaces/pages/SelectSpace/hooks/useShouldCreateSpace";

const AppRoutes = observer(() => {
  const { userSession } = userStore;
  const history = useHistory();
  const { postLoginRedirectPath } = usePostLoginRedirect();
  useShouldCreateSpace();

  React.useEffect(() => {
    if (postLoginRedirectPath && userSession.isLoggedIn) {
      if (isDev()) {
        // eslint-disable-next-line no-console
        console.log("postLoginRedirectPath detected - Redirecting to: ", postLoginRedirectPath);
      }
      history.push(webStore.postLoginRedirect);
      webStore.setPostLoginRedirect("");
    }
  }, [postLoginRedirectPath, userSession.isLoggedIn, history]);

  const isAuthenticateRoute = !userSession.isLoggedIn;
  const isMainRoute = !isAuthenticateRoute;

  return (
    <>
      {isAuthenticateRoute ? <AuthenticationRoutes /> : null}
      {isMainRoute ? <MainRouter /> : null}
    </>
  );
});

export default AppRoutes;
