import { useState } from "react";
import { useForm, SubmitHandler } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { spacesClient, eolasLogger } from "@eolas-medical/core";
import InstitutionDetailsForm from "./InstitutionDetailsForm";
import InstitutionDetailsFreeForm from "./InstitutionDetailsFreeForm";
import { FORM_FIELDS } from "../../constants/formFields";
import { useMutation } from "@tanstack/react-query";

interface InstitutionOption {
  label: string;
  value: string;
  __isNew__?: boolean;
}

interface UseInstitutionDetailsStepProps {
  onSubmit: (institutions: string[]) => void;
  isUKIrelandUser: boolean;
  isLoading: boolean;
  backgroundColor?: string;
}

interface UKIrelandFormData {
  institutions: InstitutionOption[];
}

interface NonUKIrelandFormData {
  institution: string;
}

export const useInstitutionDetailsStep = ({
  onSubmit,
  isUKIrelandUser,
  isLoading,
  backgroundColor,
}: UseInstitutionDetailsStepProps): Step<typeof FORM_FIELDS.INSTITUTION_DETAILS> => {
  const { t } = useTranslation();
  const [institutionError, setInstitutionError] = useState<string | null>(null);

  const ukIrelandForm = useForm<UKIrelandFormData>({
    defaultValues: {
      institutions: [],
    },
    resolver: yupResolver(
      yup.object().shape({
        institutions: yup
          .array()
          .of(
            yup.object().shape({
              label: yup.string().required(),
              value: yup.string().required(),
              __isNew__: yup.boolean().optional(),
            }),
          )
          .min(1, t("validation_institution_required"))
          .required(t("validation_institution_required")),
      }),
    ),
    mode: "onChange",
  });

  const nonUKIrelandForm = useForm<NonUKIrelandFormData>({
    defaultValues: {
      institution: "",
    },
    resolver: yupResolver(
      yup.object().shape({
        institution: yup.string().required(t("validation_institution_required")),
      }),
    ),
    mode: "onChange",
  });

  const createInstitutionMutation = useMutation({
    mutationFn: async (name: string) => {
      const response = await spacesClient.createInstitution(name);
      if (!response?.id) {
        throw new Error("No institution ID returned");
      }
      return response.id;
    },
    onError: (error) => {
      eolasLogger.error(new Error("Failed to create institution"), { error });
      setInstitutionError(t("error_create_institution"));
    },
  });

  const handleUKIrelandSubmit: SubmitHandler<UKIrelandFormData> = async (data) => {
    const institutionValues = data.institutions.map(
      (institution: InstitutionOption) => institution.value,
    );
    onSubmit(institutionValues);
  };

  const handleNonUKIrelandSubmit: SubmitHandler<NonUKIrelandFormData> = async (data) => {
    const institutionName = data.institution.trim();
    if (!institutionName) return;

    setInstitutionError(null);
    createInstitutionMutation.mutate(institutionName, {
      onSuccess: (institutionId) => {
        onSubmit([institutionId]);
      },
    });
  };

  return {
    name: FORM_FIELDS.INSTITUTION_DETAILS,
    title: t("complete_profile_institution_title"),
    subtitle: t("complete_profile_institution_subtitle"),
    component: isUKIrelandUser ? (
      <InstitutionDetailsForm control={ukIrelandForm.control} backgroundColor={backgroundColor} />
    ) : (
      <InstitutionDetailsFreeForm
        control={nonUKIrelandForm.control}
        backgroundColor={backgroundColor}
      />
    ),
    onSubmit: isUKIrelandUser
      ? ukIrelandForm.handleSubmit(handleUKIrelandSubmit)
      : nonUKIrelandForm.handleSubmit(handleNonUKIrelandSubmit),
    canProceed: isUKIrelandUser
      ? ukIrelandForm.formState.isValid
      : nonUKIrelandForm.formState.isValid,
    isLoading:
      (isUKIrelandUser
        ? ukIrelandForm.formState.isSubmitting
        : nonUKIrelandForm.formState.isSubmitting) ||
      isLoading ||
      createInstitutionMutation.isLoading,
    submitError:
      (isUKIrelandUser ? !ukIrelandForm.formState.isValid : !nonUKIrelandForm.formState.isValid) ||
      !!institutionError,
  };
};
