import React from "react";
import { Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormElement } from "UIKit";
import { jobTitleOptions, seniorityOptions, specialtyOptions } from "@eolas-medical/core";
import { EnhancedSyncSelect } from "../../shared/EnhancedSyncSelect";

interface WorkDetailsFormProps {
  control: Control<{
    jobTitle: { value: string; label: string } | undefined;
    seniority: { value: string; label: string } | undefined;
    specialty: { value: string; label: string }[];
  }>;
}

const WorkDetailsForm: React.FC<WorkDetailsFormProps> = ({ control }) => {
  const { t } = useTranslation();

  return (
    <div className="w-full flex flex-col gap-0 xl:gap-8">
      <div className="mb-8">
        <FormElement
          labelClassName="text-base"
          required
          id="jobTitle"
          control={control}
          label={t("profile_job_title_label")}
        >
          {(fieldProps) => (
            <EnhancedSyncSelect
              {...fieldProps}
              options={jobTitleOptions}
              placeholder={t("profile_job_title_placeholder")}
              isSearchable
              className="job-title-select"
              classNamePrefix="job-title-select"
              menuPlacement="bottom"
            />
          )}
        </FormElement>
      </div>

      <div className="mb-8">
        <FormElement
          labelClassName="text-base"
          required
          id="seniority"
          control={control}
          label={t("profile_seniority_label")}
        >
          {(fieldProps) => (
            <EnhancedSyncSelect
              {...fieldProps}
              options={seniorityOptions}
              placeholder={t("profile_seniority_placeholder")}
              isSearchable
              className="seniority-select"
              classNamePrefix="seniority-select"
              menuPlacement="bottom"
            />
          )}
        </FormElement>
      </div>

      <div>
        <FormElement
          labelClassName="text-base"
          required
          id="specialty"
          control={control}
          label={t("profile_specialty_label")}
        >
          {(fieldProps) => (
            <EnhancedSyncSelect
              {...fieldProps}
              options={specialtyOptions}
              placeholder={t("profile_specialty_placeholder")}
              isMulti
              isSearchable
              className="specialty-select"
              classNamePrefix="specialty-select"
              menuPlacement="top"
            />
          )}
        </FormElement>
      </div>
      <div className="2xl:h-40" />
    </div>
  );
};

export default WorkDetailsForm;
