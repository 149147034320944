import { useState } from "react";
import { Space } from "@eolas-medical/core";
import { Modal } from "UIKit";
import { queryClient } from "config/reactQuery";

import SpaceTile from "../../../SpaceTile/SpaceTile";
import { PreviewSpaceModal } from "../PreviewSpaceModal/PreviewSpaceModal";

export interface DiscoverSpaceItemProps {
  space: Space;
}

export const DiscoverSpaceItem: React.FC<DiscoverSpaceItemProps> = ({
  space,
}: DiscoverSpaceItemProps) => {
  const [showRequestAccess, setShowRequestAccess] = useState(false);

  const handleShowModal = () => {
    setShowRequestAccess(true);

    // Ensure we have fresh data when opening the modal
    queryClient.invalidateQueries(["checkSpaceAccess", space.id]);
  };

  const handleCloseModal = () => {
    setShowRequestAccess(false);

    // Invalidate queries when closing the modal to ensure data is fresh
    queryClient.invalidateQueries(["checkSpaceAccess", space.id]);
  };

  return (
    <>
      <SpaceTile
        id={space.id}
        name={space.name}
        description={space.description}
        membersCount={space.userCount}
        imageUrl={space.image?.url}
        isPrivate={space.accessType === "private"}
        specialties={space.specialty || []}
        onClick={handleShowModal}
      />
      <Modal open={showRequestAccess} onClose={handleCloseModal}>
        <PreviewSpaceModal space={space} onClose={handleCloseModal} />
      </Modal>
    </>
  );
};
