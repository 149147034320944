import { AppLevelSection, MedusaStatus, UserInvitationStatus } from "@eolas-medical/core";
import {
  Section,
  Checklist,
  PublishFileInput,
  ChildReference as ApiChildRef,
} from "@eolas-medical/core/lib/src/graphql";
import { App } from "API";
import { ContentRepositoryId } from "modules/contentRepository/types";

export type Maybe<T> = T | null | undefined;

export interface SubSectionRouteParams {
  sectionID: string;
}

export type EolasChecklist = Omit<Checklist, "__typename">;

export enum AppEnv {
  DEVELOPMENT = "development",
  STAGING = "staging",
  PRODUCTION = "production",
}

export type Hierarchy = "parent" | "child";

export enum UserRole {
  user = "user",
  admin = "admin",
}

export type AllLevelAppIDs =
  | "departmentLevelAppID"
  | "departmentLevelMainOrganisationID"
  | "hospitalLevelOrganisationID"
  | "hospitalLevelOrganisationTrustID";

export enum BaseAppRoute {
  myProfile = "my-profile",
  department = "department",
  hospital = "hospital",
  userGuide = "userGuide",
  selectOrganisation = "select-organisation",
  spaces = "spaces",
  organisation = "organisation",
  selectSpace = "spaces/select-space",
  knowledge = "knowledge",
  chat = "chat",
  viewer = "documents/viewer",
}

export enum CalculatorSections {
  creatinineCalculator = "creatinine_clearance_calculator",
  bodyWeightCalculator = "ideal_body_weight_calculator",
  gentamicinCalculator = "gentamicin_dosage_calculator",
}

export enum MedusaMainSections {
  medusaFiles = "medusaFiles",
}

export const sectionsExcludedFromTracking = [
  AppLevelSection.howTo,
  AppLevelSection.wellbeing,
  AppLevelSection.equipmentLocations,
];

export const NO_EXPIRY_DATE = "function not used";

// enum for each section's subsections

export enum MajorIncidentType {
  active = "active",
  deactivated = "deactivated",
}

export enum MajorIncidentSubSection {
  documents = "majorIncidentDocuments",
  incidents = "incidents",
}

export enum IncidentReportingResolveStatus {
  resolved = "resolved",
  notResolved = "notResolved",
}

// S3 related values

export enum AppUserAccessLevels {
  ADMIN = "admin",
  READ_ONLY = "readonly",
}

export type EolasChecklistSection = Omit<Section, "__typename" | "metadata"> & {
  metadata: Checklist;
};

export type ChildReference = Omit<ApiChildRef, "__typename">;

export type PublishFileDTO = PublishFileInput & { id?: string };

export declare type AccessRequest = {
  id: string;
  appID: string;
  userId: string;
  familyName?: string | null;
  givenName?: string | null;
  family_name?: string | null;
  given_name?: string | null;
  email?: string | null;
  deleted?: string | null;
  app: App;
  readGroups?: Array<string | null> | null;
  createdAt: string;
  updatedAt: string;
  requestText?: string;
};

export const LANDING_PAGE_SLUG = "landing-page";

// stores and services types

export enum LDFlagNames {
  // features
  WEB_MAINTENANCE = "web-maintenance",
  ADMIN_INVITE_USERS = "admin-invite-users",
  PDF_TRON_ENABLED = "pdf-tron-rollout",
  MS_OFFICE_FILES_ENABLED = "ms-office-files",
  UPDATED_SEARCH = "updated-search",
  COMMUNITY_TAB = "community-tab",
  MY_PORTFOLIO = "my-portfolio",
  MY_FILES = "my-files",
  NOTIFICATION_CENTER = "notification-center",
  CHECKLIST_OWNERS = "checklist-owners",
  PORTFOLIO_WIZARD = "portfolio-wizard",
  NEW_SIGN_IN = "new-sign-in",
  GENERIC_CONTENT_REPOSITORY = "generic-content-repository",
  FLASHCARDS = "flashcards",
  EOLAS_EDITOR = "eolas-editor",
  MULTIPLE_SUBSECTIONS = "multiple-subsections",
  SHADOW_COPYING = "shadow-copying",
  VERSION_HISTORY = "version-history",

  // department tab sections
  CLINICAL_PATHWAYS_SECTION = "clinical-pathways-section",
  SURVEYS_SECTION = "surveys-section",
  WELLBEING_SECTION = "wellbeing-section",
  EQUIPMENT_LOCATIONS_SECTION = "equipment-locations-section",
  HOW_TO_SECTION = "how-to-section",
  ADMISSION_GUIDES_SECTION = "admission-guides-section",
  PATIENT_LEAFLETS_SECTION = "patient-leaflets-section",
  THE_DEPARTMENT_SECTION = "the-department-section",
  HIGHLIGHTS_SECTION = "highlights-section",
  NEWS_FEED_SECTION = "news-feed-section",
  CHECKLIST_SECTION = "checklists-section",
  GUIDELINES_SECTION = "guidelines-section",
  CLINICAL_DOCUMENTS_SECTION = "clinical-documents-section",
  POLICY_DOCUMENTS_SECTION = "policy-documents-section",
  EMERGENCY_ACTION_CARDS_SECTION = "emergency-action-cards-section",
  EDUCATION_PORTAL_SECTION = "education-portal-section",
  ROTA_SECTION = "rota-section",
  CONTACTS_SECTION = "contacts-section",
  INCIDENT_REPORTING_SECTION = "incident-reporting-section",
  MEET_THE_TEAM_SECTION = "meet-the-team-section",
  HOSPITAL_LISTS_SECTION = "hospital-lists-section",
  MAJOR_INCIDENT_SECTION = "major-incident-section",
  IMPORTANT_LINKS_SECTION = "important-links-section",
  RESEARCH_AND_AUDIT = "research-and-audit-section",
  NOT_USED = "section-not-used",
  CHAT_SECTION = "chat-section",
  ACTIVITY_FEED_SECTION = "activity-feed-section",
  DSM_EDITING = "should-allow-dsm-editing",
  ASK_EOLAS_SPACES_SECTION = "ask-eolas-spaces-section",

  // hospital tab sections
  DRUG_MONITORING_SECTION = "drug-monitoring-section",
  HOSPITAL_GUIDELINES_SECTION = "hospital-guidelines-section",
  HOSPITAL_CONTACTS_SECTION = "hospital-contacts-section",
  HOSPITAL_CLINICAL_CALCULATORS_SECTION = "hospital-clinical-calculators-section",
  MEDUSA_SECTIONS = "medusa-medications-section",
  LEARNING_MANAGEMENT = "learning-management",
  COMMUNICATION_PORTAL = "communication-portal",
  GENERIC_ORGANISATION_GUIDELINES_SECTION = "generic-organisation-guidelines-section",
  ORGANISATION_WELLBEING_SECTION = "organisation-wellbeing-section",
  PUBLIC_FILES = "public-files",
  ORG_PATIENT_LEAFLETS_SECTION = "org-patient-leaflets",

  // community tab section
  NATIONAL_GUIDELINES = "national-guidelines-section",
  CT_CLINICAL_CALCULATORS = "community-tab-clinical-calculators",
  COMMUNITY_PATIENT_LEAFLETS = "community-patient-leaflets",
  COMMUNITY_BACKGROUND_SEARCH = "community-background-search",
  US_COMMUNITY_CONTENT = "us-community-content",
  COMMUNITY_MEDICATIONS_SECTION = "community-medications-section",
  KNOWLEDGE_ADMIN_ENABLED = "knowledge-admin",
  PHARMA_RESOURCES = "pharma-resources",
  COPILOT_COMMUNITY_SEARCH = "copilot-community-search",
  WIKIEM_FEATURE = "wiki-em-feature",
  BNF_INTERACTIONS_FEATURE = "bnf-interactions-feature",
  BNF_WEB = "bnf-web",
  NICE_GUIDELINES_SECTION = "nice-guidelines-section",
  SPONSORED_SLOTS = "sponsored-slots",

  // me tab section
  TEACHING_DELIVERY = "teaching-delivery",
  MY_FAVOURITES = "my-favourites",

  // utils
  FORCE_COOKIES = "force-cookies-modal",
  MEDUSA_WEB_VIEW = "medusa-web-viewer",
  SHOW_CREATE_ORG_BUTTON = "show-create-organisation",
  REDIRECT_CREATE_SPACE_CONTACT_US = "redirect-create-space-contact-us",
  DISABLE_MEDUSA_ACTIVATION = "disable-medusa-activation",
  NOTIFICATIONS_BANNER = "notification-banner",
  FEDERATED_USERS = "federated-users",
  REGION_SPECIFIC_TARGETING = "region-specific-targeting",
  WHATS_NEW = "new-feature-modal",
  MAKE_GUIDELINE_EXPIRY_MANDATORY = "make-guideline-expiry-mandatory",
  READ_ONLY_ACTIVATED = "readonly-mode-activated",
  CHAT_FEATURE = "chat-feature", // Chat is not used in web
  NEW_APP_REDIRECT = "new-app-redirect",
  WEB_PUSH_NOTIFICATIONS = "web-push-notifications",
  ANALYTICS_NEWSFEED = "news-feed-analytics",
  NEWSFEED_OPEN_RATE_TREND = "news-feed-open-rate-trend",
  DISCOVER_SPACES_FILTER = "discover-spaces-filter",
  DISCOVER_SPACES_SUGGESTED = "discover-spaces-suggested",
  SHOW_COPILOT_SEARCH_SUGGESTIONS = "show-copilot-search-suggestions",
  SHOW_CREATE_SPACE_TEMPLATE_STEP = "CREATE_SPACE_TEMPLATE_STEP",
  SHOULD_SHOW_DIRECT_TO_MOBILE_ON_DEEPLINK_LANDING_PAGE = "should-show-direct-to-mobile-on-deeplink-landing-page",
  ALLOW_DRAFTING_ITEMS = "allow-drafting-items",
  SHARE_FILE = "shareable-file-links",
  GENERATE_DEEPLINKS_TO_SECTIONS = "generate-deeplinks-to-sections",
  SHOW_SUBSECTIONS_IN_GCR_SEARCH = "show-subsections-in-content-repository-search",
  SHOW_EOLAS_CLINICAL_QUESTIONS_BROWSER = "show-eolas-clinical-questions-browser",
  SHOW_EOLAS_CLINICAL_QUESTION_GENERATOR = "show-eolas-clinical-question-generator",
  SHOULD_SHOW_CLINICAL_QUESTIONS_BETA_TAG = "should-show-clinical-questions-beta-tag",
  HIDE_EXPIRY_DATE_ON_GCR_TILE = "hide-expiry-date-in-gcr-tile",
  TNC_LAST_UPDATED = "terms-and-conditions-last-updated",
  SHOULD_OVERRIDE_VIEWER_CSS = "should-override-viewer-css",
  SHOULD_SHOW_COPILOT_CLINICAL_QUESTIONS = "should-show-copilot-clinical-questions",
  FEATURE_GUIDE_MODALS = "feature-guide-modals",
  SHOW_DEEPLINK_INFO_MODAL = "deeplink-redirect-info-modal",
  USE_APP_SERVICES_ENDPOINTS = "use-app-services-endpoints", // TODO: this flag can be removed once we're sure that all the endpoints created to replace the app services ones works well
  SHOW_LEGACY_GUIDELINE_EXPIRY_WARNING = "legacy-guideline-expiry-warning",
  SHOULD_SHOW_ORGANISATION_ADMIN_DETAILS = "should-show-organisation-admin-details",
  SHOULD_ENABLE_TINYMCE_CLOUD = "enable-tinymce-cloud",
  SHARE_MEDUSA_ITEMS = "share-medusa-items",
  SHOULD_SKIP_ACCOUNT_CHECK = "should-skip-account-check",
  MAIN_SECTION_CONTENT_IN_PARALLEL = "main-section-content-in-parallel",
  ENABLE_CHECKLIST_SNAPSHOT_DOWNLOAD = "enable-checklist-snapshot-download",

  // Belfast Trust Portfolio Events
  BELFAST_TRUST_PORTFOLIO_EVENTS = "belfast-trust-portfolio-events",

  // Calculators
  EOLAS_GENTAMICIN_CALC = "eolas-gentamicin-calc",
  EOLAS_CREATININE_CLEARANCE_CALC = "eolas-creatinine-clearance-calc",
  EOLAS_IDEAL_BODY_WEIGHT_CALC = "eolas-ideal-body-weight-calc",
}

export interface LDFlags {
  // features
  [LDFlagNames.WEB_MAINTENANCE]?: boolean;
  [LDFlagNames.ADMIN_INVITE_USERS]?: boolean;
  [LDFlagNames.PDF_TRON_ENABLED]?: boolean;
  [LDFlagNames.MS_OFFICE_FILES_ENABLED]?: boolean;
  [LDFlagNames.COMMUNITY_TAB]?: boolean;
  [LDFlagNames.MY_PORTFOLIO]?: boolean;
  [LDFlagNames.MY_FILES]?: boolean;
  [LDFlagNames.CHECKLIST_OWNERS]?: boolean;
  [LDFlagNames.PORTFOLIO_WIZARD]?: boolean;
  [LDFlagNames.NEW_SIGN_IN]?: boolean;
  [LDFlagNames.CHAT_FEATURE]?: "enabled" | "disabled" | "registration-only" | "requires-update";
  [LDFlagNames.GENERIC_CONTENT_REPOSITORY]?: boolean;
  [LDFlagNames.FLASHCARDS]?: boolean;
  [LDFlagNames.EOLAS_EDITOR]?: boolean;
  [LDFlagNames.MULTIPLE_SUBSECTIONS]?: boolean;
  [LDFlagNames.SHADOW_COPYING]?: boolean;
  [LDFlagNames.VERSION_HISTORY]?: boolean;

  // department tab sections
  [LDFlagNames.CLINICAL_PATHWAYS_SECTION]?: boolean;
  [LDFlagNames.SURVEYS_SECTION]?: boolean;
  [LDFlagNames.WELLBEING_SECTION]?: boolean;
  [LDFlagNames.EQUIPMENT_LOCATIONS_SECTION]?: boolean;
  [LDFlagNames.HOW_TO_SECTION]?: boolean;
  [LDFlagNames.ADMISSION_GUIDES_SECTION]?: boolean;
  [LDFlagNames.PATIENT_LEAFLETS_SECTION]?: boolean;
  [LDFlagNames.THE_DEPARTMENT_SECTION]?: boolean;
  [LDFlagNames.HIGHLIGHTS_SECTION]?: boolean;
  [LDFlagNames.NEWS_FEED_SECTION]?: boolean;
  [LDFlagNames.CHECKLIST_SECTION]?: boolean;
  [LDFlagNames.GUIDELINES_SECTION]?: boolean;
  [LDFlagNames.CLINICAL_DOCUMENTS_SECTION]?: boolean;
  [LDFlagNames.POLICY_DOCUMENTS_SECTION]?: boolean;
  [LDFlagNames.EMERGENCY_ACTION_CARDS_SECTION]?: boolean;
  [LDFlagNames.EDUCATION_PORTAL_SECTION]?: boolean;
  [LDFlagNames.ROTA_SECTION]?: boolean;
  [LDFlagNames.CONTACTS_SECTION]?: boolean;
  [LDFlagNames.INCIDENT_REPORTING_SECTION]?: boolean;
  [LDFlagNames.MEET_THE_TEAM_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_LISTS_SECTION]?: boolean;
  [LDFlagNames.MAJOR_INCIDENT_SECTION]?: boolean;
  [LDFlagNames.IMPORTANT_LINKS_SECTION]?: boolean;
  [LDFlagNames.RESEARCH_AND_AUDIT]?: boolean;
  [LDFlagNames.NOT_USED]?: boolean;
  [LDFlagNames.CHAT_SECTION]?: boolean;
  [LDFlagNames.ACTIVITY_FEED_SECTION]?: boolean;
  [LDFlagNames.DSM_EDITING]?: boolean;
  [LDFlagNames.ASK_EOLAS_SPACES_SECTION]?: boolean;

  // hospital tab sections
  [LDFlagNames.DRUG_MONITORING_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_GUIDELINES_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_CONTACTS_SECTION]?: boolean;
  [LDFlagNames.HOSPITAL_CLINICAL_CALCULATORS_SECTION]?: boolean;
  [LDFlagNames.MEDUSA_SECTIONS]?: boolean;
  [LDFlagNames.LEARNING_MANAGEMENT]?: boolean;
  [LDFlagNames.COMMUNICATION_PORTAL]?: boolean;
  [LDFlagNames.GENERIC_ORGANISATION_GUIDELINES_SECTION]?: boolean;
  [LDFlagNames.ORGANISATION_WELLBEING_SECTION]?: boolean;
  [LDFlagNames.PUBLIC_FILES]?: boolean;
  [LDFlagNames.ORG_PATIENT_LEAFLETS_SECTION]?: boolean;

  // community tab section
  [LDFlagNames.NATIONAL_GUIDELINES]?: boolean;
  [LDFlagNames.CT_CLINICAL_CALCULATORS]?: boolean;
  [LDFlagNames.COMMUNITY_PATIENT_LEAFLETS]?: boolean;
  [LDFlagNames.COMMUNITY_BACKGROUND_SEARCH]?: boolean;
  [LDFlagNames.US_COMMUNITY_CONTENT]?: boolean;
  [LDFlagNames.COMMUNITY_MEDICATIONS_SECTION]?: boolean;
  [LDFlagNames.KNOWLEDGE_ADMIN_ENABLED]?: boolean;
  [LDFlagNames.PHARMA_RESOURCES]?: boolean;
  [LDFlagNames.COPILOT_COMMUNITY_SEARCH]?: boolean;
  [LDFlagNames.WIKIEM_FEATURE]?: boolean;
  [LDFlagNames.BNF_INTERACTIONS_FEATURE]?: boolean;
  [LDFlagNames.BNF_WEB]?: boolean;
  [LDFlagNames.NICE_GUIDELINES_SECTION]?: boolean;
  [LDFlagNames.SHOW_EOLAS_CLINICAL_QUESTIONS_BROWSER]?: boolean;
  [LDFlagNames.SHOW_EOLAS_CLINICAL_QUESTION_GENERATOR]?: boolean;
  [LDFlagNames.SHOULD_SHOW_CLINICAL_QUESTIONS_BETA_TAG]?: boolean;
  [LDFlagNames.SPONSORED_SLOTS]?: boolean;

  // me tab section
  [LDFlagNames.TEACHING_DELIVERY]?: boolean;
  [LDFlagNames.MY_FAVOURITES]?: boolean;

  // utils
  [LDFlagNames.FORCE_COOKIES]?: boolean;
  [LDFlagNames.UPDATED_SEARCH]?: boolean;
  [LDFlagNames.SHOW_CREATE_ORG_BUTTON]?: boolean;
  [LDFlagNames.REDIRECT_CREATE_SPACE_CONTACT_US]?: boolean;
  [LDFlagNames.MEDUSA_WEB_VIEW]?: boolean;
  [LDFlagNames.DISABLE_MEDUSA_ACTIVATION]?: boolean;
  [LDFlagNames.NOTIFICATIONS_BANNER]?: string;
  [LDFlagNames.FEDERATED_USERS]?: boolean;
  [LDFlagNames.REGION_SPECIFIC_TARGETING]?: boolean;
  [LDFlagNames.WHATS_NEW]?: string;
  [LDFlagNames.MAKE_GUIDELINE_EXPIRY_MANDATORY]?: boolean;
  [LDFlagNames.READ_ONLY_ACTIVATED]?: boolean;
  [LDFlagNames.NEW_APP_REDIRECT]?: boolean;
  [LDFlagNames.WEB_PUSH_NOTIFICATIONS]?: boolean;
  [LDFlagNames.NOTIFICATION_CENTER]?: boolean;
  [LDFlagNames.ANALYTICS_NEWSFEED]?: boolean;
  [LDFlagNames.NEWSFEED_OPEN_RATE_TREND]?: boolean;
  [LDFlagNames.DISCOVER_SPACES_FILTER]?: boolean;
  [LDFlagNames.DISCOVER_SPACES_SUGGESTED]?: boolean;
  [LDFlagNames.SHOW_COPILOT_SEARCH_SUGGESTIONS]?: boolean;
  [LDFlagNames.SHOW_CREATE_SPACE_TEMPLATE_STEP]?: boolean;
  [LDFlagNames.SHOULD_SHOW_DIRECT_TO_MOBILE_ON_DEEPLINK_LANDING_PAGE]?: boolean;
  [LDFlagNames.ALLOW_DRAFTING_ITEMS]?: boolean;
  [LDFlagNames.SHOW_SUBSECTIONS_IN_GCR_SEARCH]?: boolean;
  [LDFlagNames.HIDE_EXPIRY_DATE_ON_GCR_TILE]?: boolean;
  [LDFlagNames.TNC_LAST_UPDATED]?: string;
  [LDFlagNames.SHOULD_OVERRIDE_VIEWER_CSS]?: boolean;
  [LDFlagNames.SHOULD_SHOW_COPILOT_CLINICAL_QUESTIONS]?: boolean;
  [LDFlagNames.FEATURE_GUIDE_MODALS]?: boolean;
  [LDFlagNames.SHOW_DEEPLINK_INFO_MODAL]?: boolean;
  [LDFlagNames.USE_APP_SERVICES_ENDPOINTS]?: boolean;
  [LDFlagNames.SHOW_LEGACY_GUIDELINE_EXPIRY_WARNING]?: boolean;
  [LDFlagNames.SHOULD_SHOW_ORGANISATION_ADMIN_DETAILS]?: boolean;
  [LDFlagNames.SHOULD_ENABLE_TINYMCE_CLOUD]?: boolean;
  [LDFlagNames.SHARE_MEDUSA_ITEMS]?: boolean;
  [LDFlagNames.SHOULD_SKIP_ACCOUNT_CHECK]?: boolean;
  [LDFlagNames.MAIN_SECTION_CONTENT_IN_PARALLEL]?: boolean;
  [LDFlagNames.ENABLE_CHECKLIST_SNAPSHOT_DOWNLOAD]?: boolean;
  // Belfast Trust Portfolio Events
  [LDFlagNames.BELFAST_TRUST_PORTFOLIO_EVENTS]?: boolean;

  // Deeplinking
  [LDFlagNames.SHARE_FILE]?: boolean;
  [LDFlagNames.GENERATE_DEEPLINKS_TO_SECTIONS]?: boolean;

  // Calculators
  [LDFlagNames.EOLAS_GENTAMICIN_CALC]?: boolean;
  [LDFlagNames.EOLAS_CREATININE_CLEARANCE_CALC]?: boolean;
  [LDFlagNames.EOLAS_IDEAL_BODY_WEIGHT_CALC]?: boolean;
}

export type S3GeneratorParams = {
  fileName: string;
  fileFormat: string;
  isPublic?: boolean;
  addExtension?: boolean;
  mainSectionId?: ContentRepositoryId;
};

export type OnPickFileConfig = {
  isPublic?: boolean;
  filePreview?: string;
  addExtension?: boolean;
};

export type OnPickFileFunction = (file: File | null, config: OnPickFileConfig) => void;

export interface MedusaData {
  apiKey?: string;
  status?: MedusaStatus;
  errorMessage?: string;
  cached?: boolean;
}

export interface UserInvitation {
  appID: string;
  appUserID: string;
  email: string;
  id: string;
  info: string;
  status: UserInvitationStatus;
  userID: string;
}

export interface DragEndResult {
  sourceIndex: number;
  targetIndex?: number;
}

export enum NewsItemFileType {
  newsFeed = "newsFeed",
  newsFeedPDF = "pdf",
  newsFeedMp4 = "mp4",
  newsFeedLink = "link",
}

export interface CompletedFileProps {
  isChecked: boolean;
  isCompleted: boolean;
  completionLabel: string;
  isButtonDisabled: boolean;
}

export enum DateFormat {
  DD_MM_YYY = "do MMMM yyyy",
}

export type ActiveTab = "my-profile" | "knowledge" | "spaces" | "organisation" | "select-space";

// TODO: these should not be directly displayed in the UI, a map should be created to display translated values
export enum Action {
  DELETE = "Delete",
  EDIT = "Edit",
  DOWNLOAD = "Download",
  PREVIEW = "Preview",
}

export enum ValidVideoExtension {
  MP4 = "mp4",
  MOV = "mov",
}

export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export type FilePickerAllowedTypes =
  | "pdf"
  | "link"
  | "ms-office"
  | "mp4"
  | ""
  | "blob"
  | "image"
  | "eolas-editor"
  | "extension";

type ExtractParam<Path, NextPart> = Path extends `:${infer Param}`
  ? Record<Param, string> & NextPart
  : NextPart;

export type ExtractRouteParams<Path> = Path extends `${infer Segment}/${infer Rest}`
  ? ExtractParam<Segment, ExtractRouteParams<Rest>>
  : // eslint-disable-next-line @typescript-eslint/no-empty-object-type
    ExtractParam<Path, {}>;

// Internal only not to be exported
type StringableKey<T> = T extends readonly unknown[]
  ? number extends T["length"]
    ? number
    : `${number}`
  : string | number;

/**
 * Alternative to keyof but with dot notation paths
 * borrowed from here - https://github.com/microsoft/TypeScript/issues/20423#issuecomment-813992417
 * @example
 * type Paths Array<Pathof<{ id: string, meta: { metaItem: string }}>>
 * const paths: Paths = ["id", "meta.id"]
 */
export type PathOf<T> = T extends object
  ? {
      [P in keyof T & StringableKey<T>]: `${P}` | `${P}.${PathOf<T[P]>}`;
    }[keyof T & StringableKey<T>]
  : never;
