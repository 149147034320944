import React from "react";
import Select, { GroupBase, OnChangeValue, ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { createSelectStyles } from "../../shared/selectStyles";
import { hasProp } from "@eolas-medical/core";

export interface ProfessionalBodyOption {
  value: string;
  label: string;
  flag?: string;
  isCustom?: boolean;
  isNotApplicable?: boolean;
}

interface EnhancedProfessionalBodySelectProps<
  Option extends ProfessionalBodyOption = ProfessionalBodyOption,
  IsMulti extends boolean = false,
> {
  loadOptions?: (inputValue: string) => Promise<Option[]>;
  onChange?: (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => void;
  placeholder?: string;
  isMulti?: IsMulti;
  value?: OnChangeValue<Option, IsMulti>;
  name?: string;
  options: Option[];
  isSearchable?: boolean;
  isClearable?: boolean;
  className?: string;
  classNamePrefix?: string;
  onCustomOptionSelected?: () => void;
  menuPlacement?: "auto" | "bottom" | "top";
}

export const EnhancedProfessionalBodySelect = <
  Option extends ProfessionalBodyOption = ProfessionalBodyOption,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  onChange,
  placeholder,
  isMulti,
  value,
  name,
  options,
  isSearchable = true,
  isClearable = false,
  className,
  classNamePrefix,
  onCustomOptionSelected,
  menuPlacement = "auto",
}: EnhancedProfessionalBodySelectProps<Option, IsMulti>) => {
  const { t } = useTranslation();

  const customOption: Option = {
    value: "CUSTOM",
    label: t("complete_profile_verification_choose_different_body"),
    isCustom: true,
  } as Option;

  const allOptions = [...options, customOption];

  const handleChange = (
    newValue: OnChangeValue<Option, IsMulti>,
    actionMeta: ActionMeta<Option>,
  ) => {
    if (onChange) {
      onChange(newValue, actionMeta);

      if (
        actionMeta.action === "select-option" &&
        !Array.isArray(newValue) &&
        hasProp(newValue, "isCustom") &&
        newValue.isCustom
      ) {
        if (onCustomOptionSelected) {
          onCustomOptionSelected();
        }
      }
    }
  };

  return (
    <Select<Option, IsMulti, Group>
      onChange={handleChange}
      placeholder={placeholder}
      isMulti={isMulti}
      value={value}
      name={name}
      options={allOptions}
      menuPlacement={menuPlacement}
      isSearchable={isSearchable}
      isClearable={isClearable}
      className={className}
      classNamePrefix={classNamePrefix}
      styles={createSelectStyles(false, "flag")}
    />
  );
};
