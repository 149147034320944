import { userStore } from "@eolas-medical/core";
import { useMutation } from "@tanstack/react-query";

import { sendZapierRequest } from "shared/functions/sendZapierRequest";

export type FormValues = {
  spaceName: string;
  organisationName: string;
  referralSource: string;
};

const BASE_URL = "https://hooks.zapier.com/hooks/catch/9636928/29a2vw2/";

export const useCreateSpaceZap = () => {
  const firstName = userStore.userData.givenName ?? "no firstName";
  const lastName = userStore.userData.familyName ?? "no lastName";
  const email = userStore.userData.email ?? "noEmail";
  const userId = userStore.userID ?? "noUserId";
  const jobTitle = userStore.userData.jobTitle ?? "noJobTitle";
  const specialties = userStore.userData.specialty?.join(",") ?? "noSpecialties";
  const { city, country } = userStore.userData.location ?? {};
  const location = city && country ? `${city}, ${country}` : "noLocation";
  const seniority = userStore.userData.seniority ?? "noSeniority";

  const {
    isLoading,
    isError,
    isSuccess,
    mutate: createSpaceZap,
  } = useMutation({
    mutationKey: ["createSpaceZap"],
    mutationFn: ({ organisationName, spaceName, referralSource }: FormValues) =>
      sendZapierRequest({
        type: "POST",
        baseUrl: BASE_URL,
        body: {
          firstName,
          lastName,
          email,
          userId,
          jobTitle,
          specialties,
          location,
          organisationName,
          spaceName,
          seniority,
          referralSource,
        },
      }),
  });

  return {
    createSpaceZap,
    isLoading,
    isError,
    isSuccess,
  };
};
