import { Step } from "UIKit";
import { useTranslation } from "react-i18next";
import SuccessForm from "./SuccessForm";

interface UseSuccessStepProps {
  onContinue: () => void;
  isLoading: boolean;
}

export const useSuccessStep = ({ onContinue, isLoading }: UseSuccessStepProps): Step<"success"> => {
  const { t } = useTranslation();

  return {
    name: "success",
    component: (
      <SuccessForm
        title={t("complete_profile_success_title")}
        subtitle={t("complete_profile_success_message")}
      />
    ),
    onSubmit: onContinue,
    canProceed: true,
    isLoading: isLoading,
    nextLabel: t("complete_profile_success_button"),
    backDisabled: true,
    onlySubmitStep: true,
  };
};
