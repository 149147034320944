import React from "react";
import { OnChangeValue, ActionMeta } from "react-select";
import { useTranslation } from "react-i18next";
import { EnhancedSelect } from "./EnhancedSelect";

export interface EnhancedAsyncSelectProps<Option = unknown, IsMulti extends boolean = false> {
  onChange?: (newValue: OnChangeValue<Option, IsMulti>, actionMeta: ActionMeta<Option>) => void;
  placeholder?: string;
  isMulti?: IsMulti;
  value?: OnChangeValue<Option, IsMulti>;
  name?: string;
  isSearchable?: boolean;
  isClearable?: boolean;
  className?: string;
  classNamePrefix?: string;
  loadOptions: (inputValue: string) => Promise<Option[]>;
  onMenuOpen?: () => void;
  menuPlacement?: "auto" | "bottom" | "top";
}

export const EnhancedAsyncSelect = <Option = unknown, IsMulti extends boolean = false>({
  onChange,
  placeholder,
  isMulti,
  value,
  name,
  isSearchable = true,
  isClearable = false,
  className,
  classNamePrefix,
  loadOptions,
  onMenuOpen,
  menuPlacement = "auto",
}: EnhancedAsyncSelectProps<Option, IsMulti>) => {
  const { t } = useTranslation();

  return (
    <EnhancedSelect
      isAsync
      menuPlacement={menuPlacement}
      loadOptions={loadOptions}
      onChange={onChange}
      placeholder={placeholder || t("component_eolasSelect_placeholder_search")}
      isMulti={isMulti}
      value={value}
      name={name}
      isSearchable={isSearchable}
      isClearable={isClearable}
      className={className}
      classNamePrefix={classNamePrefix}
      onMenuOpen={onMenuOpen}
    />
  );
};
