import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  InnerModalWrapper,
  ModalHeader,
  ModalBody,
  Title,
  Text,
  Input,
  Button,
  FormElement,
} from "UIKit";
import { DeleteIcon } from "Assets";

interface DeleteLoginTokenModalProps {
  onClose: () => void;
  onDelete: () => void;
  isLoading: boolean;
  apiError?: string;
}

interface DeleteConfirmForm {
  confirmText: string;
}

export const DeleteLoginTokenModal = ({
  onClose,
  onDelete,
  isLoading,
  apiError,
}: DeleteLoginTokenModalProps) => {
  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    confirmText: yup
      .string()
      .required(t("manageUsers_login_tokens_delete_error"))
      .test(
        "is-delete",
        t("manageUsers_login_tokens_delete_error"),
        (value) => value?.toLowerCase() === "delete",
      ),
  });

  const { control, handleSubmit, watch } = useForm<DeleteConfirmForm>({
    defaultValues: {
      confirmText: "",
    },
    resolver: yupResolver(validationSchema),
    mode: "onChange",
  });

  const confirmText = watch("confirmText");
  const isConfirmValid = confirmText.toLowerCase() === "delete";

  const handleDeleteToken = useCallback(() => {
    if (isConfirmValid) {
      onDelete();
    }
  }, [isConfirmValid, onDelete]);

  return (
    <InnerModalWrapper>
      <ModalHeader className="flex flex-col">
        <DeleteIcon className="h-12 w-12 mb-4 text-red" />
        <Title level={5}>{t("manageUsers_login_tokens_delete_title")}</Title>
      </ModalHeader>
      <ModalBody>
        <form onSubmit={handleSubmit(handleDeleteToken)}>
          <div className="space-y-4 sm:space-y-6 mb-6 flex flex-col items-start">
            <Text level={1} className="text-center text-grey-700">
              {t("manageUsers_login_tokens_delete_message")}
            </Text>

            <Text level={2} className="text-center font-semibold text-red-600">
              {t("manageUsers_login_tokens_delete_permanent")}
            </Text>

            <div className="mt-4 w-full">
              <FormElement
                id="confirmText"
                control={control}
                className="w-full"
                required
                label={t("manageUsers_login_tokens_delete_confirm_label")}
                errorMessage={apiError}
                renderError
              >
                {(field) => (
                  <Input
                    {...field}
                    placeholder={t("manageUsers_login_tokens_delete_confirm_placeholder")}
                    className="w-full"
                  />
                )}
              </FormElement>
            </div>
          </div>

          <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-6">
            <Button
              type="button"
              color="grey"
              variant="outline"
              onClick={onClose}
              disabled={isLoading}
              className="order-1 sm:order-0"
            >
              {t("general_cancel")}
            </Button>
            <Button
              type="submit"
              color="red"
              variant="solid"
              isLoading={isLoading}
              disabled={!isConfirmValid || isLoading}
              className="order-0 sm:order-1"
            >
              {t("general_delete")}
            </Button>
          </div>
        </form>
      </ModalBody>
    </InnerModalWrapper>
  );
};

export default DeleteLoginTokenModal;
