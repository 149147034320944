import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { BaseAppRoute } from "Utilities/types";
import { modalStore } from "Stores/ModalStore";
import CreateSpaceModal from "Pages/Spaces/pages/SelectSpace/components/CreateSpaceWizard/CreateSpaceWizard";
import CompactTile from "UIKit/CompactTile/CompactTile";
import { AddNewSpaceIcon, DiscoverSpacesIcon, NationalGuidelinesIcon } from "Assets/Icons/colored";
import { AnalyticsEvents, userStore } from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { Text, Title } from "UIKit";
import useCompleteProfileModal from "Components/CompleteProfileModal/hooks/useCompleteProfileModal";

const FinalForm = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const userId = userStore.userID;
  const { forceCloseModal } = useCompleteProfileModal();

  const closeWizardAndNavigate = useCallback(
    (path: string) => {
      forceCloseModal();
      push(path);
    },
    [push, forceCloseModal],
  );

  const handleSearchSpacesClick = useCallback(() => {
    trackEvent(AnalyticsEvents.PROFILE_DETAILS_UPDATED, { userId });
    closeWizardAndNavigate(`/${BaseAppRoute.selectSpace}`);
  }, [closeWizardAndNavigate, userId]);

  const handleCreateSpaceClick = useCallback(() => {
    trackEvent(AnalyticsEvents.PROFILE_DETAILS_UPDATED, { userId });

    forceCloseModal();

    push(`/${BaseAppRoute.selectSpace}`);

    requestAnimationFrame(() => {
      modalStore.openModal({
        variant: "component",
        name: "createSpace",
        Component: <CreateSpaceModal />,
      });
    });
  }, [push, userId, forceCloseModal]);

  const handleKnowledgeHubClick = useCallback(() => {
    trackEvent(AnalyticsEvents.PROFILE_DETAILS_UPDATED, { userId });
    closeWizardAndNavigate(`/${BaseAppRoute.knowledge}`);
  }, [closeWizardAndNavigate, userId]);

  return (
    <div className="w-full">
      <div className="w-full text-center flex flex-col gap-6 mb-16">
        <Title level={7}>{t("complete_profile_final_title")}</Title>
        <Text level={1}>{t("complete_profile_final_subtitle")}</Text>
      </div>
      <div className="flex flex-col mt-8 gap-4">
        <CompactTile
          title={t("complete_profile_search_spaces_title")}
          description={t("complete_profile_search_spaces_description")}
          iconComponent={<DiscoverSpacesIcon className="w-1/2 h-1/2" />}
          onClick={handleSearchSpacesClick}
          leftBackgroundColor="bg-blue-50"
        />

        <CompactTile
          title={t("complete_profile_create_space_title")}
          description={t("complete_profile_create_space_description")}
          iconComponent={<AddNewSpaceIcon className="w-1/2 h-1/2" />}
          onClick={handleCreateSpaceClick}
          leftBackgroundColor="bg-blue-50"
        />

        <CompactTile
          title={t("complete_profile_knowledge_hub_title")}
          description={t("complete_profile_knowledge_hub_description")}
          iconComponent={<NationalGuidelinesIcon className="w-1/2 h-1/2" />}
          onClick={handleKnowledgeHubClick}
          leftBackgroundColor="bg-green-50"
        />
      </div>
      <div className="h-40" />
    </div>
  );
};

export default FinalForm;
