import { useQuery } from "@tanstack/react-query";
import { spacesClient, Space } from "@eolas-medical/core";
import { useMemo } from "react";
import { mapSpecialtyNames } from "modules/spaces/helpers";

interface UseSuggestedSpacesProps {
  institutionIds: string[];
  enabled: boolean;
}

export const useSuggestedSpaces = ({ institutionIds, enabled }: UseSuggestedSpacesProps) => {
  const { data, isFetching, error, isFetched } = useQuery(
    ["suggestedSpaces", institutionIds],
    async () => {
      const params = {
        limit: 10,
        extended: "true",
        institutionIds,
      } as const;

      return await spacesClient.listSuggestedSpaces(params);
    },
    {
      enabled,
      staleTime: 5 * 60 * 1000, // 5 minutes
    },
  );

  const suggestedSpaces: Space[] = useMemo(() => {
    if (!data?.records) {
      return [];
    }

    return data.records.map((record) => ({
      ...record,
      name: record.name,
      description: record.description,
      accessType: record.accessType,
      specialty: mapSpecialtyNames(
        Array.isArray(record?.specialty) ? record.specialty : record.specialty?.split(","),
      ),
      userCount: record.appUsersCount,
    }));
  }, [data?.records]);

  return {
    suggestedSpaces,
    isLoading: isFetching,
    error,
    dataFetched: isFetched,
  };
};
