import React from "react";
import { useTranslation } from "react-i18next";
import { MembersIcon, PrivacyIcon, defaultSpace } from "Assets";
import { IconText } from "UIKit";
import { getImageUrl } from "Utilities";
import CompactTile from "UIKit/CompactTile/CompactTile";

interface CompactSpaceTileProps {
  id: string;
  name: string;
  membersCount: number;
  isPrivate?: boolean;
  description?: string;
  imageUrl?: string;
  "data-testid"?: string;
  onClick(): void;
  isLoading?: boolean;
}

const CompactSpaceTile: React.FC<CompactSpaceTileProps> = ({
  id,
  name,
  description,
  membersCount,
  isPrivate = false,
  imageUrl,
  onClick,
  "data-testid": dataTestId,
  isLoading,
}) => {
  const { t } = useTranslation();
  const imgUrl = imageUrl ? getImageUrl(imageUrl) : defaultSpace;

  const renderSpaceInfo = () => (
    <>
      <IconText level={1} icon={<MembersIcon />} className="text-sm">
        {membersCount}
        {membersCount > 1 ? t("space_details_members") : t("space_details_member")}{" "}
      </IconText>
      {isPrivate ? (
        <IconText level={2} icon={<PrivacyIcon />} className="text-sm">
          {t("selectSpace_is_private")}
        </IconText>
      ) : null}
    </>
  );

  return (
    <CompactTile
      id={id}
      title={name}
      description={description}
      rightContent={renderSpaceInfo()}
      imageUrl={imgUrl}
      onClick={onClick}
      data-testid={dataTestId}
      isLoading={isLoading}
      leftBackgroundColor="bg-blue-50"
    />
  );
};

export default CompactSpaceTile;
