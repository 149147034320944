import { useCallback, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { WebViewerInstance } from "@pdftron/webviewer";

import { AnalyticsEvents, hasProp } from "@eolas-medical/core";
import { EolasDocumentViewerBaseProps, EolasDocumentViewerProps, GetUrl } from "./types";
import { Notification, useNotifications } from "Components/Notifications";
import { EolasDocumentViewerError, initialiseEolasDocumentViewer } from "./functions";
import { trackEvent } from "API/Analytics";
import { useRunOnMountUnmount } from "Hooks";
import { localSearchStore } from "Stores/localSearch/localSearch.store";
import { knowledgeSearchStore } from "Stores/KnowledgeSearchStore";

export const useEolasDocumentViewer = (props: EolasDocumentViewerProps) => {
  const divRef = useRef<HTMLDivElement>(null);
  const notificationRef = useRef<Notification | null>();
  const { t } = useTranslation();

  const { showNotification, hideNotification, updateNotification } = useNotifications();
  useRunOnMountUnmount({
    onUnmount: () => {
      knowledgeSearchStore.cleartextToHighlight();
      localSearchStore.clearTextToHighlight();
    },
  });
  const loadDoc = useCallback(async () => {
    let baseProps: EolasDocumentViewerBaseProps;
    let getUrl: GetUrl;
    if (hasProp(props, "getUrl")) {
      ({ getUrl, ...baseProps } = props);
    } else {
      const { url, ...newBaseProps } = props;
      getUrl = () => url;
      baseProps = newBaseProps;
    }
    trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_STARTED);
    if (baseProps.shouldShowBannerNotifications !== false) {
      notificationRef.current = showNotification({
        type: "loading",
        description: t("fileViewer_opening_pdf"),
      });
    }
    const onError = (error: EolasDocumentViewerError) => {
      trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_ERROR);
      baseProps.onError?.(error);
      if (baseProps.shouldShowBannerNotifications === false) {
        return;
      }
      if (notificationRef.current) {
        updateNotification({
          type: "error",
          id: notificationRef.current.id,
          description: t("fileViewer_error"),
        });
      }
    };
    const onLoadFile = (instance: WebViewerInstance) => {
      baseProps.onLoadFile?.(instance);
      trackEvent(AnalyticsEvents.PDF_VIEWER_FILE_OPEN_SUCCESS);
      if (baseProps.shouldShowBannerNotifications === false) {
        return;
      }
      if (notificationRef.current) {
        hideNotification(notificationRef.current.id);
      }

      if (props.textToHighlight?.length) {
        instance.UI.searchTextFull(props.textToHighlight);
      }
    };
    initialiseEolasDocumentViewer({
      ref: divRef,
      getUrl,
      ...baseProps,
      onError,
      onLoadFile,
    });
  }, [props, showNotification, hideNotification, updateNotification, t]);

  useEffect(() => {
    if (props.reRenderBehaviour === "withPropsUpdates") {
      loadDoc();
    }
  }, [props, loadDoc]);

  useRunOnMountUnmount({
    onMount: () => {
      if (props.reRenderBehaviour === "onceOnMount") {
        loadDoc();
      }
    },
    onUnmount: () => {
      if (!notificationRef.current) {
        return;
      }
      hideNotification(notificationRef.current.id);
      notificationRef.current = null;
    },
  });

  return {
    divRef,
  };
};
