import { observer } from "mobx-react-lite";
import useCompleteProfileModal from "./hooks/useCompleteProfileModal";

const CompleteProfileModal = observer(() => {
  useCompleteProfileModal();

  return null;
});

export default CompleteProfileModal;
