import React, { useCallback, useState } from "react";
import { Control, useController } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FormElement } from "UIKit";
import { FormatOptionLabelMeta } from "react-select";
import NewInstitutionConfirmationModal from "./NewInstitutionConfirmationModal";
import { spacesClient, eolasLogger } from "@eolas-medical/core";
import { useInstitutionsLoadOptions } from "Hooks/useInstitutionsLoadOptions";
import { EolasAsyncCreatableSelect } from "./AsyncCreatableSelect";
import { useMutation } from "@tanstack/react-query";
import { FORM_FIELDS } from "../../constants/formFields";

interface InstitutionOption {
  label: string;
  value: string;
  nameVariations?: string[];
  __isNew__?: boolean;
}

interface InstitutionDetailsFormValues {
  institutions: InstitutionOption[];
}

interface InstitutionDetailsFormProps {
  control: Control<InstitutionDetailsFormValues>;
  backgroundColor?: string;
}

interface InstitutionOptionLabelProps {
  option: InstitutionOption;
  context: FormatOptionLabelMeta<InstitutionOption>["context"];
}

const InstitutionOptionLabel = React.memo(function InstitutionOptionLabel({
  option,
  context,
}: InstitutionOptionLabelProps) {
  if (context === "value") {
    return <>{option.label}</>;
  }

  return (
    <div className="institution-option py-1">
      <div className="institution-name font-medium text-base leading-normal">{option.label}</div>
      {option.nameVariations?.length ? (
        <div className="institution-variations text-sm text-gray-500 mt-1 leading-snug">
          {option.nameVariations?.join(", ")}
        </div>
      ) : null}
    </div>
  );
});

const formatInstitutionOption = (
  option: InstitutionOption,
  meta: FormatOptionLabelMeta<InstitutionOption>,
) => <InstitutionOptionLabel option={option} context={meta.context} />;

const InstitutionDetailsForm = ({ control, backgroundColor }: InstitutionDetailsFormProps) => {
  const { t } = useTranslation();
  const { loadInstitutionOptions } = useInstitutionsLoadOptions();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [newInstitutionName, setNewInstitutionName] = useState("");

  const {
    field: { onChange, value, name },
  } = useController({
    name: "institutions",
    control,
  });

  const createInstitutionMutation = useMutation({
    mutationFn: async (institutionName: string) => {
      const response = await spacesClient.createInstitution(institutionName);
      if (!response?.id) {
        throw new Error("No institution ID returned");
      }
      return {
        label: institutionName,
        value: response.id,
      };
    },
    onSuccess: (newOption) => {
      onChange([...(value || []), newOption]);
      setShowConfirmationModal(false);
    },
    onError: (error) => {
      eolasLogger.error(new Error("Failed to create institution"), { error });
    },
  });

  const handleCreateNewInstitution = useCallback((inputValue: string) => {
    setNewInstitutionName(inputValue);
    setShowConfirmationModal(true);
  }, []);

  const handleConfirmNewInstitution = () => {
    createInstitutionMutation.mutate(newInstitutionName);
  };

  return (
    <div className={`w-full${backgroundColor ? ` ${backgroundColor}` : ""}`}>
      <div className="flex flex-col justify-center">
        <div className="w-full mt-8">
          <FormElement
            labelClassName="text-base"
            required
            id={FORM_FIELDS.INSTITUTIONS}
            control={control}
            label={t("complete_profile_healthcare_institution_label")}
          >
            {() => (
              <EolasAsyncCreatableSelect
                loadOptions={loadInstitutionOptions}
                onChange={onChange}
                value={value}
                name={name}
                placeholder={t("complete_profile_healthcare_institution_placeholder")}
                isSearchable
                isMulti
                debounceMs={800}
                onCreateOption={handleCreateNewInstitution}
                className="institution-select"
                classNamePrefix="institution-select"
                formatOptionLabel={formatInstitutionOption}
                menuPlacement="auto"
              />
            )}
          </FormElement>
        </div>
        <div className="h-40" />
      </div>

      {showConfirmationModal ? (
        <NewInstitutionConfirmationModal
          institutionName={newInstitutionName}
          onConfirm={handleConfirmNewInstitution}
          onCancel={() => setShowConfirmationModal(false)}
          isLoading={createInstitutionMutation.isLoading}
          error={createInstitutionMutation.error ? t("error_create_institution") : null}
        />
      ) : null}
    </div>
  );
};

export default InstitutionDetailsForm;
