import { eolasLogger } from "@eolas-medical/core";
import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeGetLoginTokensQueryKey } from "./useLoginTokens";
import spacesKeys from "modules/spaces/data/spaces.queryKeys";

export const useCreateLoginToken = () => {
  const { flags } = useLaunchDarkly();
  const { spaceId } = useGetRelevantSpaceDetails();
  const queryClient = useQueryClient();

  const {
    mutate: mutateCreateToken,
    isLoading,
    isSuccess,
    error,
    reset,
  } = useMutation<unknown, Error>({
    mutationFn: async () => {
      return loginTokenService.createToken({
        appID: spaceId ?? "",
        // eslint-disable-next-line camelcase
        given_name: "Demo",
        // eslint-disable-next-line camelcase
        family_name: "Account",
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(makeGetLoginTokensQueryKey(spaceId));
      queryClient.invalidateQueries(spacesKeys.usersList(spaceId ?? ""));
    },
    onError: (err) => {
      eolasLogger.error(new Error("Error creating login token"), { error: err.message });
    },
  });

  const createToken = () => {
    // Reset the mutation state before triggering a new mutation
    if (isSuccess) {
      reset();
    }
    mutateCreateToken();
  };

  return {
    error: error?.message || "",
    isLoading,
    isSuccessful: isSuccess,
    createToken,
  };
};
