import React from "react";

import { eolasLogger } from "@eolas-medical/core";
import { loginTokenService } from "API/LoginTokenService";
import { useLaunchDarkly } from "Contexts";
import { LDFlagNames } from "Utilities/types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { makeGetLoginTokensQueryKey } from "./useLoginTokens";
import { useGetRelevantSpaceDetails } from "../../hooks/useGetRelevantSpaceDetails";
import { useTranslation } from "react-i18next";

export const useLoginToken = (token: string) => {
  const { t } = useTranslation();
  const [tooltipText, setTooltipText] = React.useState<string>(
    t("manageUsers_login_tokens_copy_link"),
  );
  const [isDeleteModalOpen, setIsDeleteModalOpen] = React.useState<boolean>(false);
  const { flags } = useLaunchDarkly();
  const queryClient = useQueryClient();
  const { spaceId } = useGetRelevantSpaceDetails();

  const {
    mutate: deleteToken,
    isLoading,
    error,
  } = useMutation<unknown, Error>({
    mutationFn: async () => {
      return loginTokenService.deleteToken({
        token,
        useAppServicesEndpoints: flags[LDFlagNames.USE_APP_SERVICES_ENDPOINTS] || false,
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries(makeGetLoginTokensQueryKey(spaceId));
      onCloseDeleteModal();
    },
    onError: (err: Error) => {
      eolasLogger.error(new Error("Error deleting login token"), { error: err.message });
    },
  });

  const onCopyClicked = () => {
    navigator.clipboard.writeText(
      `${window.location.origin}/auto-login/${encodeURIComponent(token)}`,
    );
    setTooltipText(t("manageUsers_login_tokens_link_copied"));
  };

  const afterTooltipHide = () => {
    return setTimeout(() => {
      setTooltipText(t("manageUsers_login_tokens_copy_link"));
    }, 250);
  };

  const onOpenDeleteModal = React.useCallback(() => {
    setIsDeleteModalOpen(true);
  }, []);

  const onCloseDeleteModal = React.useCallback(() => {
    setIsDeleteModalOpen(false);
  }, []);

  return {
    isLoading,
    tooltipText,
    onDeleteToken: deleteToken,
    onCopyClicked,
    afterTooltipHide,
    isDeleteModalOpen,
    onOpenDeleteModal,
    onCloseDeleteModal,
    error: error?.message || "",
  };
};
