import { useForm } from "react-hook-form";
import { Step } from "UIKit";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { UserLocation } from "@eolas-medical/core";
import LocationForm from "./LocationForm";

interface UseLocationStepProps {
  onSubmit: (location: UserLocation, isUKIreland: boolean) => void;
  defaultLocation?: UserLocation;
  isLoading: boolean;
}

export const useLocationStep = ({
  onSubmit,
  defaultLocation,
  isLoading,
}: UseLocationStepProps): Step<"location"> => {
  const { t } = useTranslation();

  const schema = yup.object().shape({
    location: yup
      .object()
      .shape({
        value: yup.string().required(),
        label: yup.string().required(),
      })
      .required(t("validation_location_required"))
      .nullable(),
  });

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      location: defaultLocation
        ? {
            label: `${defaultLocation.city}, ${defaultLocation.country}`,
            value: `${defaultLocation.city},${defaultLocation.country}`,
          }
        : undefined,
    },
    resolver: yupResolver(schema),
    mode: "onChange",
  });

  const handleLocationSubmit = (data: {
    location:
      | {
          label: string;
          value: string;
        }
      | undefined;
  }) => {
    if (!data.location) return;

    const locationValue = data.location.value;
    const [city, country] = locationValue.split(",");

    const isUKIreland =
      country.toLowerCase().trim() === "uk" ||
      country.toLowerCase().trim() === "ireland" ||
      country.toLowerCase().trim() === "united kingdom" ||
      country.toLowerCase().trim() === "great britain";

    onSubmit({ city, country }, isUKIreland);
  };

  return {
    name: "location",
    title: t("complete_profile_location_title"),
    subtitle: t("complete_profile_location_subtitle"),
    component: <LocationForm control={control} />,
    onSubmit: handleSubmit(handleLocationSubmit),
    canProceed: formState.isValid,
    isLoading: formState.isSubmitting || isLoading,
    submitError: !formState.isValid,
  };
};
