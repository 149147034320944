import { RouteComponentProps } from "react-router-dom";
import { observer } from "mobx-react-lite";

import { removeLastUrlPath } from "Utilities";

import { InnerPageWrapper, NavButton, PageTitle } from "UIKit";

import { ChecklistRouteParams } from "../../types";
import { useGetCompletedChecklists } from "../hooks";
import { CompletedChecklistsButtons, SubmittedChecklists } from "../components";
import { FileBreadcrumbs } from "Components/FileBreadcrumbs/FileBreadcrumbs";

export const CompletedChecklists: React.FC<RouteComponentProps<ChecklistRouteParams>> = observer(
  ({
    match: {
      url,
      params: { checklistID: encodedChecklistID },
    },
  }) => {
    const checklistID = decodeURIComponent(encodedChecklistID);
    const { checklist, hasIssues, isDisabled, checklistName, files } =
      useGetCompletedChecklists(checklistID);

    return (
      <InnerPageWrapper className="space-y-8">
        <FileBreadcrumbs
          shouldShowSpaceName
          mainSectionId={checklist.mainSectionID}
          parentId={checklist.parentID ?? ""}
          fileName={checklistName}
        />
        <PageTitle title={checklistName} />
        <NavButton to={removeLastUrlPath(url, 2)} />

        <CompletedChecklistsButtons
          hasIssues={hasIssues}
          isDisabled={isDisabled}
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          checklist={checklist as any}
        />

        <SubmittedChecklists
          checklistID={checklistID}
          checklistName={checklistName}
          files={files}
        />
      </InnerPageWrapper>
    );
  },
);
