import { userStore } from "@eolas-medical/core";
import useUserDetails from "Hooks/useUserDetails";
import { useCallback, useEffect } from "react";
import { modalStore } from "Stores/ModalStore";
import { useObserver } from "mobx-react-lite";
import { useGetDisplayTsAndCsStatus } from "Pages/AppTour/hooks/useGetDisplayTsAndCsStatus";
import CompleteProfileWizard from "Components/CompleteProfileWizard/CompleteProfileWizard";

export const COMPLETE_PROFILE_MODAL_NAME = "completeProfile";

function useCompleteProfileModal() {
  const { isSharedAccount } = useUserDetails();
  const { specialty, conditionsAccepted } = userStore.userData;
  const { userID } = userStore;
  const { data: shouldDisplayTerms } = useGetDisplayTsAndCsStatus();

  const hasAccessToken = Boolean(
    userStore.userSession.inviteId || userStore.userSession.accessLinkId,
  );

  const forceProfileUpdate =
    userID &&
    !specialty &&
    !hasAccessToken &&
    !isSharedAccount &&
    Boolean(conditionsAccepted) &&
    shouldDisplayTerms?.shouldDisplay === false;

  const openModal = useCallback(() => {
    if (!(modalStore.modalState.isOpen && modalStore.modal?.name === COMPLETE_PROFILE_MODAL_NAME)) {
      modalStore.openModal({
        variant: "component",
        name: COMPLETE_PROFILE_MODAL_NAME,
        Component: CompleteProfileWizard,
        isDismissible: !forceProfileUpdate,
        shouldRenderCloseButton: !forceProfileUpdate,
      });
    }
  }, [forceProfileUpdate]);

  const closeModal = useCallback(() => {
    if (!forceProfileUpdate && modalStore.modal?.name === COMPLETE_PROFILE_MODAL_NAME) {
      modalStore.closeModal();
    }
  }, [forceProfileUpdate]);

  const forceCloseModal = useCallback(() => {
    if (modalStore.modal?.name === COMPLETE_PROFILE_MODAL_NAME) {
      modalStore.closeModal();
    }
  }, []);

  const isModalOpen = useObserver(
    () => modalStore.modalState.isOpen && modalStore.modal?.name === COMPLETE_PROFILE_MODAL_NAME,
  );

  useEffect(() => {
    if (forceProfileUpdate && !isModalOpen) {
      openModal();
    }
  }, [forceProfileUpdate, isModalOpen, openModal]);

  return {
    isModalOpen,
    openModal,
    closeModal,
    forceCloseModal,
    forceProfileUpdate,
  };
}

export default useCompleteProfileModal;
