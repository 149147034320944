import { useRouteMatch } from "react-router-dom";
import { InnerPageWrapper, PageTitle, Loader } from "UIKit";

import { eventTypeLabels } from "modules/portfolio/types";
import EventForm from "../../components/EventForm/EventForm";
import StatusBadge from "Pages/MeTab/pages/Portfolio/components/StatusBadge/StatusBadge";

import usePortfolioParams from "Pages/MeTab/pages/Portfolio/hooks/usePortfolioParams";
import useEventForm from "modules/portfolio/data/useEventForm";
import { PatientInfoDisclaimer } from "UIKit/PatientInfoDisclaimer";

export const EventDetail = () => {
  const { url } = useRouteMatch();
  const { eventId, eventType, superviseeId } = usePortfolioParams();
  const { eventForm, eventFormLoading } = useEventForm(eventType, eventId, superviseeId);

  const eventTitle = eventTypeLabels[eventType] || "";

  if (eventFormLoading) {
    return (
      <InnerPageWrapper>
        <div className="m-auto px-2 flex flex-col items-center">
          <Loader className="bg-transparent" />
        </div>
      </InnerPageWrapper>
    );
  }

  if (!eventForm) {
    return null;
  }

  const toRemovePath = eventId
    ? `/${eventType.toLowerCase()}/${eventId}`
    : `/${eventType.toLowerCase()}`;
  const backUrl = `${url.replace(toRemovePath, "")}`;

  return (
    <>
      <InnerPageWrapper>
        {!eventFormLoading ? (
          <div className="w-[20%] m-auto">
            <StatusBadge status={eventForm?.status} hasBorder />
          </div>
        ) : null}
        <PageTitle title={eventTitle} />
        <PatientInfoDisclaimer />
        <EventForm eventId={eventId} backUrl={backUrl} eventForm={eventForm} />
      </InnerPageWrapper>
    </>
  );
};
