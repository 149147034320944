import { confetti as confettiAnimation } from "Assets/LottieAnimations";
import React from "react";

import Lottie from "react-lottie-player";
import { Text } from "UIKit";

interface SuccessFormProps {
  title: string;
  subtitle: string;
}

const SuccessForm = ({ title, subtitle }: SuccessFormProps) => {
  return (
    <div className="flex flex-col items-center justify-center w-full p-8 text-center">
      <Lottie
        style={{ width: "16rem", height: "16rem" }}
        loop={false}
        play
        animationData={confettiAnimation}
      />
      <Text level={1} className="font-semibold mb-8">
        {title}
      </Text>
      <Text level={2}>{subtitle}</Text>
      <div className="xl:h-40" />
    </div>
  );
};

export default SuccessForm;
