import "react-app-polyfill/stable";
import "react-app-polyfill/ie9";
import "react-app-polyfill/ie11";
import allSettled from "promise.allsettled";

import { Suspense } from "react";
import { BrowserRouter as Router } from "react-router-dom";

import { LoadingOverlay } from "UIKit";
import { EolasProviders, Sentry } from "Contexts";
import { startup } from "Utilities/appStartup";

import "./i18n";
import "./index.css";

import App from "./App";
import { createRoot } from "react-dom/client";
import { NonDismissableModals } from "Pages/NonDismissableModals/NonDismissableModals";

startup();
allSettled.shim();

Sentry.addBreadcrumb({
  category: "Init",
  message: "imports added, calling amplify configure",
});

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <EolasProviders>
    <Suspense fallback={<LoadingOverlay />}>
      <Router>
        <App />
        <NonDismissableModals />
      </Router>
    </Suspense>
  </EolasProviders>,
);
