import { useState } from "react";
import { Step, Wizard, useWizard } from "UIKit/Wizard";
import {
  AnalyticsEvents,
  UserData,
  UserLocation,
  userStore,
  eolasLogger,
  ProfileVerificationStatus,
  ProfileVerification,
} from "@eolas-medical/core";
import { trackEvent } from "API/Analytics";
import { useWorkDetailsStep } from "./steps/WorkDetails/useWorkDetailsStep";
import { useLocationStep } from "./steps/Location/useLocationStep";
import { useInstitutionDetailsStep } from "./steps/InstitutionDetails/useInstitutionDetailsStep";
import { useProfessionalVerificationStep } from "./steps/ProfessionalVerification/useProfessionalVerificationStep";
import { useSuccessStep } from "./steps/Success/useSuccessStep";
import { useSuggestedSpacesStep } from "./steps/SuggestedSpaces/useSuggestedSpacesStep";
import { useFinalStep } from "./steps/Final/useFinalStep";
import useUpdateUserProfile from "Pages/MeTab/pages/MyProfile/hooks/useUpdateUserProfile";
import { useSuggestedSpaces } from "./hooks/useSuggestedSpaces";
import spaceBgLines from "Assets/Img/space-lines-condensed.svg";

type CompleteProfileSteps =
  | "workDetails"
  | "location"
  | "institutionDetails"
  | "professionalVerification"
  | "success"
  | "suggestedSpaces"
  | "final";

interface WizardState {
  profileData: Partial<UserData> & {
    profileVerification?: ProfileVerification;
  };
  photoBlob: Blob | null;
  isUKIrelandUser: boolean;
}

const CompleteProfileWizard = () => {
  const [wizardState, setWizardState] = useState<WizardState>({
    profileData: {
      givenName: userStore.userData.givenName,
      familyName: userStore.userData.familyName,
      jobTitle: undefined,
      seniority: undefined,
      specialty: [],
      location: { city: "", country: "" },
      profileVerification: undefined,
    },
    photoBlob: null,
    isUKIrelandUser: false,
  });

  const [showingSuggestedSpaces, setShowingSuggestedSpaces] = useState(false);
  const [showingFinalStep, setShowingFinalStep] = useState(false);
  const [submitError, setSubmitError] = useState<string | null>(null);

  const { wizardControl } = useWizard<CompleteProfileSteps>();
  const { updateUser, updatingUser } = useUpdateUserProfile();
  const { id: userId = "" } = userStore.userData;

  const { suggestedSpaces, isLoading: loadingSuggestedSpaces } = useSuggestedSpaces({
    institutionIds: wizardState.profileData.institutions || [],
    enabled: wizardState.isUKIrelandUser,
  });

  const handleWorkDetailsUpdate = (workDetails: {
    jobTitle: string;
    seniority: string;
    specialty: string[];
  }) => {
    setWizardState((prev) => ({
      ...prev,
      profileData: {
        ...prev.profileData,
        jobTitle: workDetails.jobTitle,
        seniority: workDetails.seniority,
        specialty: workDetails.specialty,
      },
    }));
    trackEvent(AnalyticsEvents.PROFILE_DETAILS_UPDATED, { userId });
  };

  const handleLocationUpdate = (location: UserLocation, isUKIreland: boolean) => {
    setWizardState((prev) => ({
      ...prev,
      profileData: {
        ...prev.profileData,
        location,
      },
      isUKIrelandUser: isUKIreland,
    }));
  };

  const handleInstitutionUpdate = async (institutions: string[]) => {
    try {
      setWizardState((prev) => ({
        ...prev,
        profileData: {
          ...prev.profileData,
          institutions,
        },
      }));
    } catch (error) {
      eolasLogger.error(new Error("Failed to handle institution"), { error });

      setWizardState((prev) => ({
        ...prev,
        profileData: {
          ...prev.profileData,
          institutions,
        },
      }));
    }
  };

  const handleProfessionalVerificationUpdate = (verification: {
    professionalBody: string;
    registrationNumber: string;
  }) => {
    const isUKIrelandUser = wizardState.isUKIrelandUser;

    if (userId) {
      let profileVerificationData;

      if (verification.professionalBody === "NOT_APPLICABLE") {
        profileVerificationData = {
          professionalBody: "NOT_APPLICABLE",
          registrationNumber: "",
          status: ProfileVerificationStatus.PENDING,
        };
      } else if (isUKIrelandUser) {
        profileVerificationData = {
          status: ProfileVerificationStatus.PENDING,
          professionalBody: verification.professionalBody,
          registrationNumber: verification.registrationNumber,
        };
      } else {
        profileVerificationData = undefined;
      }

      const completeProfileData = {
        ...wizardState.profileData,
        profileVerification: profileVerificationData,
      };

      updateUser(
        { userId, userData: completeProfileData as UserData },
        {
          onSuccess: (user) => {
            userStore.updateData({ user });
            trackEvent(AnalyticsEvents.PROFILE_DETAILS_UPDATED, { userId });
            setWizardState((prev) => ({
              ...prev,
              profileData: completeProfileData,
            }));
            setSubmitError(null);
          },
          onError: (error) => {
            eolasLogger.error(new Error("Failed to update profile"), { error });
            setSubmitError(error instanceof Error ? error.message : "Failed to update profile");
          },
        },
      );
    }
  };

  const handleSuccessContinue = () => {
    const hasValidInstitutions =
      Array.isArray(wizardState.profileData.institutions) &&
      wizardState.profileData.institutions.length > 0;

    if (wizardState.isUKIrelandUser && hasValidInstitutions && suggestedSpaces.length > 0) {
      setShowingSuggestedSpaces(true);
    } else {
      setShowingFinalStep(true);
    }
  };

  const handleSuggestedSpacesContinue = () => {
    setShowingFinalStep(true);
  };

  const workDetailsStep = useWorkDetailsStep({
    onSubmit: handleWorkDetailsUpdate,
    isLoading: updatingUser,
    defaultValues: {
      jobTitle: userStore.userData.jobTitle || "",
      seniority: userStore.userData.seniority || "",
      specialty: userStore.userData.specialty || [],
    },
  });

  const locationStep = useLocationStep({
    onSubmit: handleLocationUpdate,
    defaultLocation: userStore.userData.location,
    isLoading: false,
  });

  const institutionDetailsStep = useInstitutionDetailsStep({
    onSubmit: handleInstitutionUpdate,
    isUKIrelandUser: wizardState.isUKIrelandUser,
    isLoading: false,
  });

  const professionalVerificationStep = useProfessionalVerificationStep({
    onSubmit: handleProfessionalVerificationUpdate,
    isUKIrelandUser: wizardState.isUKIrelandUser,
    isLoading: updatingUser,
  });

  const successStep = useSuccessStep({
    onContinue: handleSuccessContinue,
    isLoading: wizardState.isUKIrelandUser ? updatingUser || loadingSuggestedSpaces : false,
  });

  if (submitError) {
    successStep.submitError = true;
  }

  const suggestedSpacesStep = useSuggestedSpacesStep({
    onNext: handleSuggestedSpacesContinue,
    suggestedSpaces,
    isLoading: loadingSuggestedSpaces,
  });

  const finalStep = useFinalStep();

  const steps = [
    workDetailsStep,
    locationStep,
    institutionDetailsStep,
    professionalVerificationStep,
    successStep,
    ...(showingSuggestedSpaces && wizardState.isUKIrelandUser ? [suggestedSpacesStep] : []),
    ...(showingFinalStep ? [finalStep] : []),
  ] as Step<CompleteProfileSteps>[];

  const modalBodyClassName =
    "max-h-100vh max-w-100vw xl:max-w-4xl w-100vw lg:w-80vw h-full overflow-y-auto bg-blue-spaces-background";

  const backgroundStyle = {
    backgroundImage: `url(${spaceBgLines})`,
    backgroundPositionY: "300px",
    backgroundRepeat: "no-repeat",
  };

  return (
    <Wizard
      getStepClassName={() => modalBodyClassName}
      wizardControl={wizardControl}
      steps={steps}
      showProgress
      showSteps
      modalBodyClassName="max-h-100vh max-w-100vw"
      backgroundStyle={backgroundStyle}
    />
  );
};

export default CompleteProfileWizard;
