import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";
import { AppLevelSection, EolasFile } from "@eolas-medical/core";
import { Button, Loader, LottieWithHeader, Modal, Text } from "UIKit";
import { DownloadIcon, noFilesFound } from "Assets";
import { EolasFileList } from "Components/EolasFileList";

import { contentDbStore } from "Pages/Spaces/stores/contentDb/contentDb.store";
import { useSubmittedChecklists } from "../hooks";
import { useDownloadChecklistSnapshot } from "../hooks/useDownloadChecklistSnapshot";
import { useState } from "react";
import { DateRangePickerModal } from "./DateRangePickerModal";

interface SubmittedChecklistsProps {
  checklistID: string;
  checklistName: string;
  files: EolasFile[];
}

const NoFiles = () => {
  const { t } = useTranslation();
  return (
    <LottieWithHeader
      animationSize="25%"
      animation={noFilesFound}
      data-testid="no-files-found"
      lottieOptions={{ loop: true }}
      text={t("general_noFilesFound")}
    />
  );
};

export const SubmittedChecklists = observer(
  ({ checklistID, checklistName, files }: SubmittedChecklistsProps) => {
    const { modal, onCloseModal, filterChecklists, onDelete, onSelect } = useSubmittedChecklists();
    const { isAddingChecklistFiles } = contentDbStore;
    const { t } = useTranslation();
    const { handleDownloadSnapshot, showDownloadSnapshot } = useDownloadChecklistSnapshot();
    const [showDateRangePicker, setShowDateRangePicker] = useState(false);

    if (isAddingChecklistFiles) {
      return (
        <div className="w-full flex flex-col justify-center items-center">
          <Loader className="bg-transparent" />
          <Text className="text-center mt-8" level={2}>
            {t("checklists_loading")}
          </Text>
        </div>
      );
    }

    const handleApplyDateRange = (startDate: Date, endDate: Date) => {
      handleDownloadSnapshot(files, checklistName, startDate, endDate);
    };

    return (
      <>
        <div className="flex justify-end mb-4">
          {showDownloadSnapshot ? (
            <Button
              size="lg"
              onClick={() => setShowDateRangePicker(true)}
              data-testid="download-snapshot-button"
              color="blue"
              variant="outline"
            >
              <DownloadIcon width={16} height={16} />
              <span className="ml-2">{t("download_snapshot")}</span>
            </Button>
          ) : null}
        </div>

        <EolasFileList
          isAdmin={true}
          defaultSort="date"
          draggable={false}
          onDelete={onDelete}
          sortDateBy="createdAt"
          className="space-y-6"
          onSelectFile={onSelect}
          listClassName="space-y-4"
          adminNoFiles={<NoFiles />}
          subSectionId={checklistID}
          filterFn={filterChecklists}
          mainSectionId={AppLevelSection.checklists}
        />

        <Modal open={!!modal} onClose={onCloseModal}>
          {modal}
        </Modal>

        <Modal open={showDateRangePicker} onClose={() => setShowDateRangePicker(false)}>
          <DateRangePickerModal
            closeModal={() => setShowDateRangePicker(false)}
            onApply={handleApplyDateRange}
          />
        </Modal>
      </>
    );
  },
);
