import React from "react";
import { Text } from "UIKit";
import { EolasCard } from "UIKit/EolasCard/EolasCard";
import useIconComponent from "Pages/Spaces/hooks/useIconComponent";
import { CaretRight } from "Assets/Icons/monocolored";

export interface CompactTileProps {
  id?: string;
  title: string;
  subtitle?: React.ReactNode;
  description?: string;
  iconUrl?: string;
  iconComponent?: React.ReactNode;
  imageUrl?: string;
  leftBackgroundColor?: string;
  rightContent?: React.ReactNode;
  "data-testid"?: string;
  onClick?: () => void;
  isLoading?: boolean;
  isDisabled?: boolean;
  showChevron?: boolean;
}

const CompactTile = ({
  title,
  subtitle,
  description,
  iconUrl,
  iconComponent,
  imageUrl,
  leftBackgroundColor = "bg-blue-50",
  rightContent,
  onClick,
  "data-testid": dataTestId,
  isLoading,
  isDisabled,
  showChevron = true,
}: CompactTileProps) => {
  const { getIconComponent } = useIconComponent("w-full h-full");

  const handleClick = () => {
    if (!isLoading && !isDisabled && onClick) {
      onClick();
    }
  };

  return (
    <EolasCard onClick={handleClick} isDisabled={isDisabled || isLoading} elevation={2}>
      <div
        className={`grid grid-cols-12 ${isLoading ? "opacity-70" : ""}`}
        style={{ minHeight: "8rem" }}
        data-testid={dataTestId}
      >
        <div
          className={`row-start-1 col-start-1 col-end-4 md:col-end-3 flex ${leftBackgroundColor} rounded-l-md relative`}
          style={{ height: "8rem" }}
        >
          {imageUrl ? (
            <img src={imageUrl} alt="" className="w-full h-full object-cover rounded-l-md" />
          ) : iconComponent ? (
            <div className="flex items-center justify-center w-full h-full p-4">
              {iconComponent}
            </div>
          ) : iconUrl ? (
            <div className="flex items-center justify-center w-full h-full p-4">
              {getIconComponent(iconUrl)}
            </div>
          ) : null}
        </div>

        <div className="row-start-1 col-start-4 md:col-start-3 col-end-12 flex flex-col justify-center p-3 space-y-2">
          <div className="flex flex-col space-y-2">
            <div className="text-lg font-medium line-clamp-1">{title}</div>

            {subtitle ? <div className="flex flex-row space-x-2">{subtitle}</div> : null}

            {rightContent ? <div className="flex flex-row space-x-2">{rightContent}</div> : null}

            {description ? (
              <Text level={1} className="text-grey-700 text-sm line-clamp-2">
                {description}
              </Text>
            ) : null}
          </div>
        </div>

        {showChevron ? (
          <div className="row-start-1 col-start-12 flex items-center justify-center">
            <CaretRight className="h-6 w-6 text-grey-600" />
          </div>
        ) : null}
      </div>
    </EolasCard>
  );
};

export default CompactTile;
