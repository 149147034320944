import { Step } from "UIKit";
import FinalForm from "./FinalForm";

export const useFinalStep = (): Step<"final"> => {
  return {
    name: "final",
    component: <FinalForm />,
    onSubmit: () => {
      // No-op - user will navigate away using tiles
    },
    hideButtons: true,
  };
};
