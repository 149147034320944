import { eolasLogger, MasterSearchFile, sectionStore, userStore } from "@eolas-medical/core";
import {
  createLocalFilesDb,
  persistSearchDb,
  populateLocalFilesDb,
  removePersistedDb,
  searchWithDb,
} from "./localSearch.functions";
import { LocalFilesSearchDb, PopulateDbParams } from "./localSearch.types";
import { LDFlags } from "Utilities/types";

class LocalSearchStore {
  private db: LocalFilesSearchDb | null = null;

  private initialisingPromise: Promise<void> | null = null;
  private updatingPromise: Promise<void> | null = null;
  textToHighlight: string = "";

  persistDb = async () => {
    if (!this.db) {
      return;
    }
    await persistSearchDb(this.db);
  };

  initialiseDb = async ({ isNew }: { isNew: boolean }) => {
    if (this.initialisingPromise) {
      return this.initialisingPromise;
    }
    this.initialisingPromise = (async () => {
      let newDb: LocalFilesSearchDb | null = null;
      try {
        if (!sectionStore.appID || !userStore.userID) {
          throw new Error("Attempted to initialise LocalSearchStore when not in space");
        }
        newDb = await createLocalFilesDb({ isNew });
      } catch (error) {
        eolasLogger.error("LocalSearchStore: unable to create new DB", { error });
      }
      if (!newDb) {
        return;
      }
      try {
        this.db = newDb;
      } catch (error) {
        eolasLogger.error("LocalSearchStore: unable to populate new DB", { error });
      }
    })();
    await this.initialisingPromise;
    this.initialisingPromise = null;
  };

  updateDb = async (args: Omit<PopulateDbParams, "searchDb">) => {
    if (this.updatingPromise) {
      await this.updatingPromise;
    }
    this.updatingPromise = (async () => {
      if (this.initialisingPromise) {
        await this.initialisingPromise;
      }
      if (!this.db) {
        eolasLogger.error("LocalSearchStore: attempted to populate DB when DB was null");
        return;
      }
      try {
        await populateLocalFilesDb({ searchDb: this.db, ...args });
      } catch (error) {
        eolasLogger.error("LocalSearchStore: attempted to update DB", { error });
      }
    })();
    await this.updatingPromise;
    this.updatingPromise = null;
  };

  search = async <T>(args: {
    shouldSearchSpace?: boolean;
    shouldSearchOrganisation: boolean;
    term: string;
    ldFlags: LDFlags;
    isInAdminMode: boolean;
    idsToInclude?: Record<string, T>;
  }): Promise<MasterSearchFile[]> => {
    if (this.updatingPromise) {
      await this.updatingPromise;
    }
    if (!this.db) {
      eolasLogger.error("LocalSearchStore: attempted to search using DB when DB was null");
      return [];
    }
    localSearchStore.setTextToHighlight(args.term);
    let masterSearchFiles: MasterSearchFile[] = [];
    try {
      masterSearchFiles = await searchWithDb({ localFilesDb: this.db, ...args });
    } catch (error) {
      eolasLogger.error("LocalSearchStore: error performing search", { error });
    }
    return masterSearchFiles;
  };

  setTextToHighlight = (term: string) => {
    this.textToHighlight = term;
  };
  clearTextToHighlight = () => {
    this.textToHighlight = "";
  };
  clearStore = () => {
    this.db = null;
    removePersistedDb();
    this.clearTextToHighlight();
  };
}

export const localSearchStore = new LocalSearchStore();
