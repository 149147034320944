import { observer } from "mobx-react-lite";
import CompleteProfileModal from "Components/CompleteProfileModal/CompleteProfileModal";
import { TermlyCookies } from "Components/TermlyCookies/TermlyCookies";
import { WhatsNewModal } from "Components/WhatsNewModal/WhatsNewModal";
import { AutoAccessAfterLogin } from "Pages/Authentication/pages/Invitations/pages/modals/AutoAccessAfterLogin";
import { useDisplayTsAndCs } from "Pages/AppTour/hooks/useDisplayTsAndCs";

// @TODO: Add queue logic. These currently have complex logic inside each of them to ensure
// correct rendering order.

export const NonDismissableModals = observer(() => {
  useDisplayTsAndCs();
  return (
    <>
      <TermlyCookies />
      <AutoAccessAfterLogin />
      <WhatsNewModal />
      <CompleteProfileModal />
    </>
  );
});
