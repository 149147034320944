import React from "react";
import Select, { GroupBase, Props as SelectProps } from "react-select";
import AsyncSelect from "react-select/async";
import { useTranslation } from "react-i18next";
import { createSelectStyles } from "./selectStyles";

export interface EnhancedSelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Omit<SelectProps<Option, IsMulti, Group>, "styles"> {
  isAsync?: boolean;
  loadOptions?: (inputValue: string) => Promise<Option[]>;
  onMenuOpen?: () => void;
}

export const EnhancedSelect = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  onChange,
  placeholder,
  isMulti,
  value,
  name,
  options,
  isSearchable = true,
  isClearable = false,
  className,
  classNamePrefix,
  isAsync = false,
  loadOptions,
  onMenuOpen,
  menuPlacement = "auto",
  ...props
}: EnhancedSelectProps<Option, IsMulti, Group>) => {
  const { t } = useTranslation();
  const customStyles = createSelectStyles<Option, IsMulti, Group>(!!isMulti);

  const commonProps = {
    onChange,
    placeholder: placeholder || t("component_eolasSelect_placeholder_search"),
    isMulti,
    value,
    name,
    styles: customStyles,
    isSearchable,
    isClearable,
    closeMenuOnSelect: !isMulti,
    menuPlacement,
    className,
    classNamePrefix,
    onMenuOpen,
    ...props,
  } as const;

  if (isAsync && loadOptions) {
    return <AsyncSelect {...commonProps} cacheOptions defaultOptions loadOptions={loadOptions} />;
  }

  return <Select {...commonProps} options={options} />;
};
