import { profilesClient, userStore, ConditionsAcceptedResponse } from "@eolas-medical/core";
import { useQuery } from "@tanstack/react-query";
import useUserDetails from "Hooks/useUserDetails";

export const makeUseGetDisplayTsAndCsStatusKey = (userId: string) => ["shouldDisplayTerms", userId];

export const useGetDisplayTsAndCsStatus = ({
  onSuccess,
}: {
  onSuccess?: (data: ConditionsAcceptedResponse) => void;
} = {}) => {
  const { userSession, userID } = userStore;
  const { isSharedAccount } = useUserDetails();
  const hasAccessToken = Boolean(userSession.inviteId || userSession.accessLinkId);

  const { data } = useQuery({
    queryKey: makeUseGetDisplayTsAndCsStatusKey(userID),
    queryFn: () => profilesClient.getConditionsAccepted(userID),
    enabled: Boolean(userSession.isLoggedIn && userID && !isSharedAccount && !hasAccessToken),
    // 5 minutes:
    staleTime: 1000 * 60 * 5,
    refetchOnWindowFocus: false,
    onSuccess,
  });

  return { data };
};
