import { accessClient } from "@eolas-medical/core";
import { useMutation, MutationOptions } from "@tanstack/react-query";
import { queryClient } from "config/reactQuery";

type RequestAccessParams = {
  requestText?: string;
  spaceId: string;
};

export const useRequestAccess = (userId: string) => {
  const { mutate, isLoading, isSuccess, reset } = useMutation(
    ({ requestText, spaceId }: RequestAccessParams) =>
      accessClient.requestDepartmentAccess(userId, spaceId, requestText || ""),
    {
      onSuccess: (_, { spaceId }) => {
        // Invalidate the space access check query to trigger a refetch
        queryClient.invalidateQueries(["checkSpaceAccess", spaceId]);

        // Also invalidate any other related queries that might depend on access status
        queryClient.invalidateQueries(["userAccess", spaceId]);
      },
    },
  );

  // This function supports both calling patterns for backward compatibility:
  // 1. requestAccess(spaceId, requestText, options) - Original pattern
  // 2. requestAccess({ spaceId, requestText }, options) - Pattern used in useSelectSpaceActions
  const requestAccess = (
    spaceIdOrParams: string | RequestAccessParams,
    requestTextOrOptions?:
      | string
      | Omit<MutationOptions<unknown, unknown, RequestAccessParams, unknown>, "mutationFn">,
    options?: Omit<MutationOptions<unknown, unknown, RequestAccessParams, unknown>, "mutationFn">,
  ) => {
    // Reset the mutation state to ensure we get fresh success/error states
    reset();

    let spaceId: string;
    let requestText: string | undefined;
    let mutateOptions:
      | Omit<MutationOptions<unknown, unknown, RequestAccessParams, unknown>, "mutationFn">
      | undefined;

    // Handle both calling patterns
    if (typeof spaceIdOrParams === "string") {
      // Pattern 1: requestAccess(spaceId, requestText, options)
      spaceId = spaceIdOrParams;
      requestText = typeof requestTextOrOptions === "string" ? requestTextOrOptions : undefined;
      mutateOptions = options;
    } else {
      // Pattern 2: requestAccess({ spaceId, requestText }, options)
      spaceId = spaceIdOrParams.spaceId;
      requestText = spaceIdOrParams.requestText;
      mutateOptions = requestTextOrOptions as Omit<
        MutationOptions<unknown, unknown, RequestAccessParams, unknown>,
        "mutationFn"
      >;
    }

    // Ensure spaceId is a string and requestText is a string or empty string
    const safeSpaceId = String(spaceId);
    const safeRequestText = requestText || "";

    return mutate(
      {
        spaceId: safeSpaceId,
        requestText: safeRequestText,
      },
      {
        ...mutateOptions,
        onSuccess: (data, variables, context) => {
          // Call the default onSuccess handler
          if (mutateOptions?.onSuccess) {
            mutateOptions.onSuccess(data, variables, context);
          }

          // Ensure we invalidate the cache even if the caller provides their own onSuccess
          queryClient.invalidateQueries(["checkSpaceAccess", safeSpaceId]);
          queryClient.invalidateQueries(["userAccess", safeSpaceId]);
        },
      },
    );
  };

  return {
    requestAccess,
    requestingAccess: isLoading,
    requestAccessSuccessful: isSuccess,
  };
};
